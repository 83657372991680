import * as React from 'react'
// import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import MuiDialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { Close as CloseIcon } from '@mui/icons-material'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function Dialog({
  title,
  content,
  ContentProps,
  buttons,
  onClose,
  onBackdropClick,
  severity,
  titleSx,
  maxWidth = 'sm',
  ...rest
}) {
  // const getDlgSx = theme => {

  // 	switch( severity )
  // 	{
  // 		case 'error':
  // 			return {
  // 				bgcolor: alpha(theme.palette.error.main, .4)
  // 			}
  // 		case 'warning':
  // 			return {
  // 				bgcolor: alpha(theme.palette.warning.main, .4)
  // 			}
  // 		case 'success':
  // 			return {
  // 				bgcolor: alpha(theme.palette.success.main, .4)
  // 			}
  // 		case 'info':
  // 			return {
  // 				bgcolor: alpha(theme.palette.info.main, .4)
  // 			}
  // 		default:
  // 			return {};
  // 	}
  // }

  return (
    <MuiDialog
      onClose={onClose}
      TransitionComponent={Transition}
      fullWidth
      // onBackdropClick={onBackdropClick}
      maxWidth={maxWidth}
      // sx={getDlgSx}
      {...rest}
    >
      {title && (
        <DialogTitle
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            fill: theme.palette.grey.main2,
            ...titleSx,
          })}
        >
          <Box sx={{ mr: 'auto' }}>{title}</Box>
          {onClose ? (
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
      )}
      {content && <DialogContent {...ContentProps}>{content}</DialogContent>}
      {Array.isArray(buttons) && (
        <DialogActions>
          {buttons.map(({ label, ...other }, index) => (
            <Button key={index} {...other}>
              {label}
            </Button>
          ))}
        </DialogActions>
      )}
    </MuiDialog>
  )
}
