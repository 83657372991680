import * as React from 'react'

import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton, InputAdornment } from '@mui/material'
import { TextField } from 'mui-rff'

// NOTE: Based on the password input field shown in the documentation:
//       https://mui.com/material-ui/react-text-field/#input-adornments
export default React.forwardRef((props, ref) => {
  const [show, setShow] = React.useState(false)
  const toggleShow = () => setShow((show) => !show)

  return (
    <TextField
      ref={ref}
      type={show ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton edge="end" onClick={toggleShow} tabIndex={-1}>
              {show ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  )
})
