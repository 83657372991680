import * as React from 'react'

import { prepareForSlot, Unstable_NumberInput as NumberInput } from '@mui/base'
import { Add, Remove } from '@mui/icons-material'
import { Box, IconButton, TextField, useThemeProps } from '@mui/material'
import { styled } from '@mui/system'

const Root = prepareForSlot(
  styled(Box, { shouldForwardProp: (prop) => prop !== 'labelDown' })(
    ({ theme, labelDown }) => ({
      display: 'inline-block',
      position: 'relative',
      // NOTE: Both Firefox 114 and Chrome 113 seem to place the margin
      // on the wrong side in the inspector when the element is rotated
      [`margin${labelDown ? 'Bottom' : 'Top'}`]: theme.spacing(0.5),
      padding: theme.spacing(1),
      ...(labelDown && { transform: 'rotate(180deg)' }),
    }),
  ),
)

const DecrementButton = styled(IconButton)(({ theme, ownerState }) => ({
  position: 'absolute',
  ...(ownerState.labelDown
    ? { right: theme.spacing(1) }
    : { left: theme.spacing(1) }),
  zIndex: 1,
}))

const IncrementButton = styled(IconButton)(({ theme, ownerState }) => ({
  position: 'absolute',
  ...(ownerState.labelDown
    ? { left: theme.spacing(1) }
    : { right: theme.spacing(1) }),
  zIndex: 1,
}))

const Input = styled(TextField)(({ theme, ownerState }) => ({
  width: '100%',
  '& .MuiInputLabel-root': {
    width: '100%',
    textAlign: 'center',
    fontSize: theme.typography.h6.fontSize,
    transformOrigin: 'center',
    transform: 'translateY(-50%)',
    ...(ownerState.labelDown && {
      transform: 'translateY(-50%) rotate(180deg)',
    }),
  },
  '& .MuiInputBase-input': {
    textAlign: 'center',
    ...(ownerState.labelDown && { transform: 'rotate(180deg)' }),
  },
  '& .MuiOutlinedInput-notchedOutline legend': {
    margin: 'auto',
    fontSize: theme.typography.h6.fontSize,
  },
}))

export const CustomNumberInput = React.forwardRef((props, ref) =>
  (({ error, label, ...rest }) => (
    <NumberInput
      ref={ref}
      slots={{
        root: Root,
        decrementButton: DecrementButton,
        incrementButton: IncrementButton,
        input: Input,
      }}
      slotProps={{
        decrementButton: { children: <Remove />, color: 'primary' },
        incrementButton: { children: <Add />, color: 'primary' },
        input: {
          // NOTE: Handle `error`, as TextField doesn't support `aria-invalid`
          // https://github.com/mui/material-ui/blob/v5.14.15/packages/mui-base/src/unstable_useNumberInput/useNumberInput.ts#L306
          error,
          InputLabelProps: { shrink: true },
          inputProps: { inputMode: 'numeric', size: 7 },
          label,
          size: 'small',
        },
      }}
      {...rest}
    />
  ))(useThemeProps({ props, name: 'CustomNumberInput' })),
)
