import React from 'react'
import { useDispatch } from 'react-redux'

import { ForwardToInbox, LockReset, PersonAdd } from '@mui/icons-material'
import { Box, Chip, Link } from '@mui/material'

import { initialize } from '~/actions/initActions'
import L from '~/components/Helpers/Lang'
import { AUTH, RESPONSE } from '~/constants'
import { routeNames } from '~/constants/uiRouteNames'
import { AUTH as URLS } from '~/constants/urls'
import { usePost } from '~/hooks/useApi'

import AuthForm from './AuthForm'
import { AuthInfo, AuthTitle } from './AuthLayout'

const Login = () => {
  const dispatch = useDispatch()
  const [login, { isPending }] = usePost(URLS.login)

  const onSubmit = React.useCallback(
    ({ email, password, remember }, _form, callback) => {
      login(
        { email, password, remember },
        (response) => {
          callback()
          dispatch({
            type: AUTH.AUTHENTICATED,
            user: response.data.user,
          })
          dispatch(initialize())
        },
        (error) => {
          callback(error.errors)
          dispatch({ type: RESPONSE.SET_ERROR, error })
        },
      )
    },
    [dispatch, login],
  )

  return (
    <>
      <AuthTitle title={<L term="title.login" />} />
      <AuthInfo />
      <AuthForm
        fields={['email', 'password' /*,'remember'*/]}
        onSubmit={onSubmit}
        submitDisabled={isPending}
        submitLabel="nav.login"
        validationRules={{
          email: 'required|email',
          password: 'required|min.string:8',
        }}
      />
      <Box
        mt={3}
        display="flex"
        justifyContent="space-evenly"
        flexWrap="wrap"
        gap={0.5}
      >
        <Link href={`${routeNames.root}${routeNames.signup}`}>
          <Chip
            clickable
            variant="outlined"
            icon={<PersonAdd fontSize="small" />}
            label={<L term="title.dont_have_an_account" />}
          />
        </Link>
        <Link href={`${routeNames.root}${routeNames.forgotPassword}`}>
          <Chip
            clickable
            variant="outlined"
            icon={<LockReset fontSize="small" />}
            label={<L term="title.forgot_your_password" />}
          />
        </Link>
        <Link href={`${routeNames.root}${routeNames.resendVerification}`}>
          <Chip
            clickable
            variant="outlined"
            icon={<ForwardToInbox fontSize="small" />}
            label={<L term="title.lost_your_verification_email" />}
          />
        </Link>
      </Box>
    </>
  )
}

export default Login
