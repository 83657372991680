import { Link as RouterLink } from 'react-router-dom'

import { ArrowBack } from '@mui/icons-material'
import { Box, Button, Link as MUILink, Typography } from '@mui/material'

import L from '~/components/Helpers/Lang'
import { routeNames } from '~/constants/uiRouteNames'

export const ErrorLayout = ({ children }) => (
  <Box
    sx={{
      height: '100%',
      p: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 1,
      textAlign: 'center',
    }}
  >
    {children}
  </Box>
)

export const ErrorContactUs = ({ code, path }) => {
  const subject = `[Skybrush Shop] ${code} Error: ${path}`
  const body = `Please detail the steps / actions that resulted in the error!`

  return (
    <Typography sx={{ textWrap: 'balance' }}>
      <L term="label.error-contact-us" partial={0} />
      <MUILink
        color="primary"
        href={`mailto:shop@skybrush.io?subject=${subject}&body=${body}`}
      >
        <L term="label.error-contact-us" partial={1} />
      </MUILink>
      <L term="label.error-contact-us" partial={2} />
    </Typography>
  )
}

export const BackToShopButton = () => (
  <Button
    size="large"
    startIcon={<ArrowBack />}
    component={RouterLink}
    to={routeNames.root}
  >
    <L term="label.back-to-shop" />
  </Button>
)
