export const isValidAccountNumber = (accountNumber) => {
  accountNumber = accountNumber.replace(/[^\d+]/g, '')

  if (!(accountNumber.length === 16 || accountNumber.length === 24))
    return false

  if (accountNumber.startsWith('0')) return false

  const isValidChunk = (chunk, i) => {
    const sum = chunk
      .slice(0, chunk.length - 1)
      .reduce((sum, number, index) => sum + number * [9, 7, 3, 1][index % 4], 0)
    return (
      (sum % 10 === 0 ? 0 : 10 - (sum % 10)) ===
      parseInt(chunk[chunk.length - 1], 10)
    )
  }

  const firstChunk = accountNumber.split('').slice(0, 8)
  const secondChunk = accountNumber.split('').slice(8)

  return isValidChunk(firstChunk, 1) && isValidChunk(secondChunk, 2)
}
