import { ACCOUNT } from '~/constants'

const INITIAL_STATE = {
  requestIsPending: false,
  profileInformation: undefined,
  contacts: undefined,
  contact: undefined,
  billingAddresses: undefined,
  billingAddress: undefined,
  macAddresses: undefined,
  quotes: undefined,
  purchases: undefined,
  downloadingToken: false,
  intentCount: undefined,
  licenses: undefined,
  countries: undefined,
}

const account = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACCOUNT.SET_REQUEST_IS_PENDING:
      return { ...state, requestIsPending: action.value }

    case ACCOUNT.CLEAR_PROFILE_INFORMATION:
      return { ...state, profileInformation: INITIAL_STATE.profileInformation }
    case ACCOUNT.SET_PROFILE_INFORMATION:
      return { ...state, profileInformation: action.profileInformation }

    case ACCOUNT.CLEAR_CONTACTS:
      return { ...state, contacts: INITIAL_STATE.contacts }
    case ACCOUNT.CLEAR_CONTACT:
      return { ...state, contact: INITIAL_STATE.contact }
    case ACCOUNT.SET_CONTACTS:
      return { ...state, contacts: action.contacts }
    case ACCOUNT.SET_CONTACT:
      return { ...state, contact: action.contact }
    case ACCOUNT.REMOVE_CONTACT:
      return {
        ...state,
        contacts: state.contacts?.filter((contact) => contact.id !== action.id),
      }

    case ACCOUNT.CLEAR_BILLING_ADDRESSES:
      return {
        ...state,
        billingAddresses: INITIAL_STATE.billingAddresses,
        countries: INITIAL_STATE.countries,
      }
    case ACCOUNT.SET_BILLING_ADDRESSES:
      return { ...state, billingAddresses: action.billingAddresses }
    case ACCOUNT.CLEAR_BILLING_ADDRESS:
      return { ...state, billingAddress: INITIAL_STATE.billingAddress }
    case ACCOUNT.SET_BILLING_ADDRESS:
      return { ...state, billingAddress: action.billingAddress }
    case ACCOUNT.REMOVE_BILLING_ADDRESS:
      return {
        ...state,
        billingAddresses: state.billingAddresses?.filter(
          (address) => address.id !== action.id,
        ),
      }

    case ACCOUNT.CLEAR_MAC_ADDRESSES:
      return { ...state, macAddresses: INITIAL_STATE.macAddresses }
    case ACCOUNT.SET_MAC_ADDRESSES:
      return { ...state, macAddresses: action.macAddresses }
    case ACCOUNT.REMOVE_MAC_ADDRESS:
      return {
        ...state,
        macAddresses: state.macAddresses?.filter(
          (address) => address.id !== action.id,
        ),
      }
    case ACCOUNT.CLEAR_QUOTES:
      return { ...state, quotes: INITIAL_STATE.quotes }
    case ACCOUNT.SET_QUOTES:
      return { ...state, quotes: action.quotes }
    case ACCOUNT.CLEAR_PURCHASES:
      return { ...state, purchases: INITIAL_STATE.purchases }
    case ACCOUNT.SET_PURCHASES:
      return { ...state, purchases: action.purchases }
    case ACCOUNT.CLEAR_LICENSES:
      return { ...state, licenses: INITIAL_STATE.licenses }
    case ACCOUNT.SET_LICENSES:
      return { ...state, licenses: action.licenses }
    case ACCOUNT.DOWNLOADING_TOKEN:
      return { ...state, downloadingToken: action.value }
    case ACCOUNT.SET_INTENT_COUNT:
      return { ...state, intentCount: action.count }
    case ACCOUNT.SET_COUNTRIES:
      return { ...state, countries: action.countries }
    case ACCOUNT.CLEAR_COUNTRIES:
      return { ...state, countries: INITIAL_STATE.countries }
    default:
      return state
  }
}

export default account
