import React from 'react'
import { Outlet } from 'react-router-dom'

import { Box } from '@mui/material'

import Header from './Header'
import Footer from './Footer'

const AppLayout = () => {
  return (
    <Box height="100%" display="flex" flexDirection="column" overflow="hidden">
      <Header />
      <Box component="main" flex="auto" overflow="auto">
        <React.Suspense>
          <Outlet />
        </React.Suspense>
      </Box>
      <Footer />
    </Box>
  )
}

export default AppLayout
