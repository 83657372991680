import React from 'react'
import { Helmet } from 'react-helmet'

const Analytics = () => (
  <Helmet>
    <script
      defer
      data-domain={window.location.hostname}
      src="https://analytics.skybrush.io/js/script.js"
    ></script>
  </Helmet>
)

export default Analytics
