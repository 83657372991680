import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'

import { AUTH, RESPONSE } from '~/constants'
import { routeNames } from '~/constants/uiRouteNames'
import useResponseState from '~/hooks/useResponseState'

const ErrorHandler = ({ children }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const response = useResponseState()

  React.useEffect(() => {
    switch (response.status) {
      case 401: {
        dispatch({ type: AUTH.UNAUTHENTICATED })
        navigate(routeNames.root + routeNames.login, {
          state: { from: location },
        })
        dispatch({ type: RESPONSE.CLEAR })
        break
      }

      case 403: {
        navigate(routeNames.root + routeNames.forbidden, {
          state: { from: location },
        })
        dispatch({ type: RESPONSE.CLEAR })
        break
      }

      case 404: {
        navigate(routeNames.root + routeNames.notFound, {
          state: { from: location },
        })
        dispatch({ type: RESPONSE.CLEAR })
        break
      }

      // NOTE: Unofficial Laravel error returned on CSRF validation failure
      case 419: {
        // TODO: Redirect page that explains why the site is being reloaded
        // navigate(routeNames.root + routeNames.pageExpired, {
        //   state: { from: location },
        // })
        navigate(0)
        dispatch({ type: RESPONSE.CLEAR })
        break
      }

      // no default
    }
  }, [dispatch, navigate, location, response])

  return children
}

export default ErrorHandler
