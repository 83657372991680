import * as React from 'react'

import { Box, ListItem, ListItemIcon, ListItemText } from '@mui/material'

export default React.forwardRef(
  ({ icon, primary, secondary, IconProps, TextProps, ...rest }, ref) => (
    <ListItem ref={ref} component={Box} disablePadding {...rest}>
      <ListItemIcon {...IconProps}>{icon}</ListItemIcon>
      <ListItemText primary={primary} secondary={secondary} {...TextProps} />
    </ListItem>
  ),
)
