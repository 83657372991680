import { groupBy } from 'lodash-es'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

import {
  AddShoppingCart,
  ArrowBack,
  Description as DocumentIcon,
  Discount,
  OndemandVideo as VideoIcon,
  RequestQuote,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  ListItemText,
  Typography,
} from '@mui/material'

import {
  clearProduct,
  createPurchaseIntent,
  getProduct,
  getProductAssetDownloadToken,
} from '~/actions/productActions'
import Detail from '~/components/Custom/Detail'
import FeatureComparisonTable from '~/components/Custom/FeatureComparisonTable'
import L from '~/components/Helpers/Lang'
import DashboardProductListItem from '~/components/Pages/Dashboard/DashboardProductListItem'
import PurchaseIntent from '~/components/Pages/Purchase/PurchaseIntent'
import { routeNames } from '~/constants/uiRouteNames'
import { useDict } from '~/hooks/useDict'
import useStoreState from '~/hooks/useStoreState'
import { useDownloadToken } from '~/hooks/useTokenDownload'
import { priceFormat } from '~/utils/numberFormat'
import withStoreState from '~/utils/withStoreState'

import AddedToCartDialog from './AddedToCartDialog'
import GetAQuoteDialog from './GetAQuoteDialog'
import GetAQuoteSuccessDialog from './GetAQuoteSuccessDialog'

const Product = ({ product }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { label } = useDict()

  const [getAQuoteDialogOpened, setGetAQuoteDialogOpened] =
    React.useState(false)
  const [getAQuoteSuccessDialogOpened, setGetAQuoteSuccessDialogOpened] =
    React.useState(false)
  const [addedToCartDialogOpened, setAddedToCartDialogOpened] =
    React.useState(false)

  const openGetAQuoteDialog = React.useCallback(
    () => setGetAQuoteDialogOpened(true),
    [],
  )
  const closeGetAQuoteDialog = React.useCallback(
    () => setGetAQuoteDialogOpened(false),
    [],
  )
  const showGetAQuoteSuccessDialog = React.useCallback(
    () => setGetAQuoteSuccessDialogOpened(true),
    [],
  )
  const hideGetAQuoteSuccessDialog = React.useCallback(
    () => setGetAQuoteSuccessDialogOpened(false),
    [],
  )
  const openAddedToCartDialog = React.useCallback(
    () => setAddedToCartDialogOpened(true),
    [],
  )
  const closeAddedToCartDialog = React.useCallback(
    () => setAddedToCartDialogOpened(false),
    [],
  )

  const handleGetAQuoteSuccess = React.useCallback(() => {
    showGetAQuoteSuccessDialog.call()
    closeGetAQuoteDialog.call()
  }, [showGetAQuoteSuccessDialog, closeGetAQuoteDialog])

  const handleRedirectToCart = React.useCallback(() => {
    closeAddedToCartDialog()
    navigate(`/${routeNames.purchase}`)
  }, [navigate, closeAddedToCartDialog])

  const [pendingAction, setPendingAction] = React.useState(() => {
    const pendingActionCandidate = JSON.parse(
      window.localStorage.getItem('pendingAction'),
    )
    return (
      pendingActionCandidate?.target?.pathname === location?.pathname &&
      pendingActionCandidate?.date > Date.now() - 5 * 60 * 1000 && // 5 minutes
      pendingActionCandidate
    )
  }, [])

  const initialIntent = React.useMemo(
    () => ({
      product,
      price_factors: product.price_factors.map((pf) => ({
        ...pf,
        current_value:
          pendingAction?.factorAmounts?.[pf.price_factor_id] ?? pf.min,
      })),
    }),
    [pendingAction, product],
  )

  const [factorAmounts, setFactorAmounts] = React.useState(
    Object.fromEntries(
      initialIntent.price_factors.map((pf) => [
        pf.price_factor_id,
        pf.current_value,
      ]),
    ),
  )

  const handleIntentUpdate = React.useCallback((_intentId, { factors }) => {
    if (factors) {
      setFactorAmounts(
        Object.fromEntries(
          factors.map((pf) => [pf.price_factor_id, pf.current_value]),
        ),
      )
    }
  }, [])

  return (
    <Container sx={{ py: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box
        display="grid"
        gap={2}
        sx={{
          gridTemplateColumns: { md: 'repeat(2, minmax(0, 1fr))' },
          gridTemplateAreas: Boolean(product?.can_be_purchased)
            ? {
                xs: `
                  'title'
                  'image'
                  'factors'
                  'cta'
                `,
                md: `
                  'image   title'
                  'factors cta'
                `,
                lg: `
                  'image title  '
                  'image factors'
                  'image cta    '
                `,
              }
            : {
                xs: `
                  'title'
                  'image'
                  'cta'
                `,
                md: `
                  'image title  '
                  'image cta    '
                `,
              },
        }}
      >
        <Box gridArea="title">
          <Typography variant="h3">{product.texts?.product_name}</Typography>
          <Typography variant="h6" sx={{ fontWeight: 400, pr: 10 }}>
            {product.texts?.product_tagline}
          </Typography>
          {Boolean(product?.can_be_purchased) &&
            product?.price_factors?.length > 0 && (
              <Box display="flex" alignItems="center">
                <Typography variant="h5">
                  {priceFormat(product.price.price)}
                </Typography>
                <Typography ml={1}>{`/ ${product.price_factors
                  .map((f) => label(`factor.${f.factor.name}`))
                  .join(' / ')}`}</Typography>
              </Box>
            )}
          {product.volume_discount?.levels
            ?.toReversed()
            ?.map(({ conditions, ...rest }) => (
              <VolumeDiscount
                // TODO: find a better key here?
                key={JSON.stringify(conditions)}
                conditions={conditions.map(({ price_factor_id, value }) => ({
                  price_factor_name: product.price_factors.find(
                    (pf) => pf.price_factor_id === price_factor_id,
                  ).factor.name,
                  value,
                }))}
                type={product.volume_discount.type}
                {...rest}
              />
            ))}
        </Box>
        <Box gridArea="image" display="flex" justifyContent="center">
          <Box
            component="img"
            src={`${product.images?.[0]?.url}/${product.images[0]?.file_name}`}
            sx={{ width: '100%', borderRadius: 1, objectFit: 'contain' }}
          />
        </Box>
        {Boolean(product?.can_be_purchased) &&
          product?.price_factors?.length > 0 && (
            <Box
              gridArea="factors"
              sx={{
                position: 'relative',
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              <Box width="100%" display="flex" flexDirection="column">
                <PurchaseIntent
                  intent={initialIntent}
                  handleUpdate={handleIntentUpdate}
                  handleUpdateTimeoutMs={0}
                  hiddenTitle={true}
                  deleteEnabled={false}
                />
              </Box>
            </Box>
          )}
        <Box
          gridArea="cta"
          display="flex"
          alignItems="center"
          justifyContent="space-evenly"
        >
          <CTA
            factorAmounts={factorAmounts}
            openAddedToCartDialog={openAddedToCartDialog}
            openGetAQuoteDialog={openGetAQuoteDialog}
            pendingAction={pendingAction}
            product={product}
            setPendingAction={setPendingAction}
          />
        </Box>
      </Box>

      <Divider />

      <Typography variant="subtitle1">
        {product.texts?.product_description}
      </Typography>

      <Divider />

      <Grid container spacing={1}>
        {product.features?.map((feature) => (
          <ProductFeature key={feature.id} feature={feature} />
        ))}
      </Grid>

      <Divider />

      {product.feature_comparison && (
        <>
          <FeatureComparisonTable data={product.feature_comparison} />
          <Divider />
        </>
      )}

      {product.assets.length > 0 && (
        <>
          <ProductAssetGroups assets={product.assets} />
          <Divider />
        </>
      )}

      <Typography variant="subtitle1">
        <L term="label.related-products" />
      </Typography>

      <Grid container spacing={2}>
        {product.related_products?.map((product) => (
          <DashboardProductListItem key={product.id} product={product} />
        ))}
      </Grid>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          size="large"
          startIcon={<ArrowBack />}
          variant="contained"
          component={Link}
          to={routeNames.root}
        >
          <L term="button.back-to-products" />
        </Button>
      </Box>

      <GetAQuoteDialog
        open={getAQuoteDialogOpened}
        onClose={closeGetAQuoteDialog}
        onSuccess={handleGetAQuoteSuccess}
        product={product}
      />
      <GetAQuoteSuccessDialog
        open={getAQuoteSuccessDialogOpened}
        onClose={hideGetAQuoteSuccessDialog}
        product={product}
      />
      <AddedToCartDialog
        open={addedToCartDialogOpened}
        title={<L term="title.product-added-to-cart" />}
        onClose={closeAddedToCartDialog}
        onSuccess={handleRedirectToCart}
      />
    </Container>
  )
}

const formatDiscountAmountForType = (type, amount) => {
  switch (type) {
    case 'percent': {
      return `${amount}%`
    }

    case 'total':
    case 'unit': {
      return priceFormat(amount)
    }

    default: {
      return 'N/A'
    }
  }
}

const VolumeDiscount = ({ amount, conditions, type, ...rest }) => (
  <Detail
    icon={<Discount />}
    primary={
      <span>
        <L term="label.volume-discount" partial={0} />
        {formatDiscountAmountForType(type, Number(amount))}
        <L term="label.volume-discount" partial={1} />
        <L term={`label.discount.${type === 'percent' ? 'total' : type}`} />
        <L term="label.volume-discount" partial={2} />
        {conditions
          .flatMap(({ price_factor_name, value }) => [
            ', ',
            <Typography
              key={`${value}-${price_factor_name}`}
              component="span"
              textTransform="lowercase"
            >
              {value} <L term={`label.factor.${price_factor_name}.s`} />
            </Typography>,
          ])
          .slice(1)}
        <L term="label.volume-discount" partial={3} />
      </span>
    }
    {...rest}
  />
)

const ProductFeature = ({ feature }) => {
  return (
    <Grid item xs={6} md={4}>
      <ListItemText
        primary={feature.texts?.feature_title}
        secondary={feature.texts?.feature_description}
      />
    </Grid>
  )
}

const ProductAssetGroups = ({ assets }) => (
  <Box display="flex" flexWrap="wrap">
    {Object.entries(groupBy(assets, (a) => a.kind)).map(([kind, assets]) => (
      <Box key={kind}>
        <Typography variant="subtitle1">
          <L term={`kind.${kind}.s`} />
        </Typography>
        {assets?.map((asset) => (
          <ProductAsset key={asset.id} asset={asset} />
        ))}
      </Box>
    ))}
  </Box>
)

const ProductAsset = ({ asset }) => {
  const { downloadingToken } = useStoreState('products')
  const { getDownloadToken, DownloadComponent } = useDownloadToken()

  // const dispatch = useDispatch();
  // const [downloadUrl, setDownloadUrl ] = React.useState(undefined);
  // const [downloadLink, setDownloadLink ] = React.useState(undefined);

  // React.useEffect(() => {
  // 	if (downloadLink)
  // 	{
  // 		downloadLink.click();
  // 		setDownloadUrl(undefined);
  // 		setDownloadLink(undefined);
  // 	}
  // }, [downloadLink]);

  // const download = React.useCallback( token => {
  // 	setDownloadUrl(`${process.env.REACT_APP_API_URL}/${routeNames.download}/${token}`)
  // }, []);

  // const handleGetDownloadToken = React.useCallback(() => {
  // 	dispatch(getProductAssetDownloadToken(asset?.id, download));
  // }, [dispatch, download, asset?.id]);

  const handleGetDownloadToken = React.useCallback(() => {
    getDownloadToken(getProductAssetDownloadToken, asset.id)
  }, [getDownloadToken, asset.id])

  if (!asset) {
    return null
  }

  let properties = {}

  switch (asset.content_type) {
    case 'link':
      switch (asset.kind) {
        case 'video':
          return (
            <YoutubeVideo
              title={asset?.texts?.asset_name}
              src={asset?.content}
            />
          )
        default:
          properties = {
            ...properties,
            component: Link,
            to: asset.content,
            target: '_blank',
          }
      }
      break
    default:
      properties = {
        ...properties,
        onClick: handleGetDownloadToken,
      }
      break
  }

  return (
    <>
      <Button
        disabled={downloadingToken}
        // variant="outlined"
        size="small"
        startIcon={<ProductAssetIcon kind={asset.kind} />}
        sx={{ m: 1 }}
        {...properties}
      >
        {asset.texts?.asset_name}
      </Button>
      {/* {downloadUrl && (
				<Box
					component="a"
					sx={{display: "none"}}
					href={downloadUrl}
					ref={ref => setDownloadLink(ref)}
					// download={`${asset.texts?.name}`}
				></Box>
			)} */}
      {DownloadComponent}
    </>
  )
}

const ProductAssetIcon = ({ kind }) => {
  switch (kind) {
    case 'document':
      return <DocumentIcon />
    case 'video':
      return <VideoIcon />
    default:
      return null
  }
}

const YoutubeVideo = ({ width = 280, height = 152, src, title }) => {
  return (
    <iframe
      style={{ margin: '8px' }}
      width={width}
      height={height}
      src={src}
      title={title}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    ></iframe>
  )
}

const CTA = ({
  factorAmounts,
  openAddedToCartDialog,
  openGetAQuoteDialog,
  pendingAction,
  product,
  setPendingAction,
}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const { productIsInPendingState } = useStoreState('products')
  const { isSignedIn } = useStoreState('auth')

  const redirectTologinWithPendingAction = React.useCallback(
    (properties) => {
      window.localStorage.setItem(
        'pendingAction',
        JSON.stringify({
          date: Date.now(),
          target: location,
          ...properties,
        }),
      )

      navigate(`/${routeNames.login}`, { state: { from: location } })
    },
    [location, navigate],
  )

  const getAQuoteAfterLogin = React.useCallback(() => {
    redirectTologinWithPendingAction({ type: 'getAQuote' })
  }, [redirectTologinWithPendingAction])

  const addToCartAfterLogin = React.useCallback(() => {
    redirectTologinWithPendingAction({ type: 'addToCart', factorAmounts })
  }, [factorAmounts, redirectTologinWithPendingAction])

  const createIntent = React.useCallback(() => {
    dispatch(
      createPurchaseIntent(
        {
          product_id: product.id,
          price_factors: Object.entries(factorAmounts).map(
            ([price_factor_id, current_value]) => ({
              price_factor_id,
              current_value,
            }),
          ),
        },
        () => openAddedToCartDialog.apply(),
      ),
    )
  }, [dispatch, factorAmounts, openAddedToCartDialog, product])

  React.useEffect(() => {
    switch (pendingAction?.type) {
      case 'getAQuote': {
        openGetAQuoteDialog()
        break
      }

      case 'addToCart': {
        createIntent()
        break
      }

      // no default
    }

    setPendingAction()
    window.localStorage.removeItem('pendingAction')
  }, [createIntent, location, openGetAQuoteDialog])

  return (
    <>
      {Boolean(product.available_for_offer) && (
        <Button
          size="large"
          variant="contained"
          disabled={productIsInPendingState}
          onClick={isSignedIn ? openGetAQuoteDialog : getAQuoteAfterLogin}
          startIcon={<RequestQuote />}
        >
          {isSignedIn ? (
            <L term="button.get-a-quote" />
          ) : (
            <L term="button.log-in-and-get-a-quote" />
          )}
        </Button>
      )}

      {Boolean(product.can_be_purchased) &&
        product?.price_factors?.length > 0 && (
          <Button
            size="large"
            variant="contained"
            disabled={productIsInPendingState}
            onClick={isSignedIn ? createIntent : addToCartAfterLogin}
            startIcon={<AddShoppingCart />}
          >
            {isSignedIn ? (
              <L term="button.add-to-cart" />
            ) : (
              <L term="button.log-in-and-add-to-cart" />
            )}
          </Button>
        )}
    </>
  )
}

export default withStoreState(
  () => {
    const { productId } = useParams()
    return [productId]
  },
  getProduct,
  () => {
    const { product } = useStoreState('products')
    return { product }
  },
  ({ product }) => Boolean(product),
  clearProduct,
)(Product)
