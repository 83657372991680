import React from 'react'
import { useLocation } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import notFoundDay from '~/assets/images/404-day.jpg'
import notFoundNight from '~/assets/images/404-night.jpg'
import L from '~/components/Helpers/Lang'

import { BackToShopButton, ErrorContactUs, ErrorLayout } from '.'

const NotFound = () => {
  const { state } = useLocation()
  const from = state?.from?.pathname ?? window.location.pathname

  return (
    <ErrorLayout>
      <Box
        component={'img'}
        title={[
          'Generated by DALL-E 3:',
          '"A 404 error made out of colorful drones in the (night) sky"',
        ].join('\n')}
        sx={({ palette: { mode } }) => ({
          content: `url(${mode === 'dark' ? notFoundNight : notFoundDay})`,
          minHeight: 0,
          maxWidth: '100%',
          maskImage: 'radial-gradient(black 30%, transparent 70%)',
        })}
      />
      <Typography variant="h4">
        <L term="label.not-found" />
      </Typography>
      <ErrorContactUs code={404} path={from} />
      <BackToShopButton />
    </ErrorLayout>
  )
}

export default NotFound
