import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { adminRouteNames } from '~/constants/adminuiRouteNames'
import { routeNames } from '~/constants/uiRouteNames'
import history from '~/history'

import AppLayout from './AppLayout'
import AuthLayout from './Auth/AuthLayout'
import Login from './Auth/Login'
import RequireAuth from './Auth/RequireAuth'
import ErrorHandler from './Helpers/ErrorHandler'
import { tabList as userTabList } from './Pages/Account/AccountTabs'
import { tabList as adminTabList } from './Pages/Admin/AdminTabs'
import Dashboard from './Pages/Dashboard/Dashboard'
import Forbidden from './Pages/Error/Forbidden'
import NotFound from './Pages/Error/NotFound'
import PaymentResult from './Pages/Payment/PaymentResult'
import Product from './Pages/Product/Product'

const TabLayout = React.lazy(() => import('./TabLayout'))

/* Authentication */
const SignUp = React.lazy(() => import('./Auth/SignUp'))
const ForgotPassword = React.lazy(() => import('./Auth/ForgotPassword'))
const ResetPassword = React.lazy(() => import('./Auth/ResetPassword'))
const ResendVerification = React.lazy(() => import('./Auth/ResendVerification'))
const EmailVerification = React.lazy(() => import('./Auth/EmailVerification'))

/* Account */
const Settings = React.lazy(() => import('./Pages/Account/Settings'))
const AccountBillingAddressList = React.lazy(() =>
  import('./Pages/Account/BillingAddresses/AccountBillingAddressList'),
)
const AccountContactList = React.lazy(() =>
  import('./Pages/Account/Contacts/AccountContactList'),
)
const MacAddressList = React.lazy(() =>
  import('./Pages/Account/MacAddresses/MacAddressList'),
)
const QuoteList = React.lazy(() =>
  import('./Pages/Account/QuoteRequests/QuoteList'),
)
const PurchaseList = React.lazy(() =>
  import('./Pages/Account/Purchases/PurchaseList'),
)
const LicenseList = React.lazy(() =>
  import('./Pages/Account/Licenses/LicenseList'),
)

const AccountContactEdit = React.lazy(() =>
  import('./Pages/Account/Contacts/AccountContactEdit'),
)
const AccountBillingAddressEdit = React.lazy(() =>
  import('./Pages/Account/BillingAddresses/AccountBillingAddressEdit'),
)
const PurchaseIntentList = React.lazy(() =>
  import('./Pages/Purchase/PurchaseIntentList'),
)

/* Admin */
const AdminQuotes = React.lazy(() => import('./Pages/Admin/AdminQuotes'))
const AdminQuoteDetails = React.lazy(() =>
  import('./Pages/Admin/AdminQuoteDetails'),
)
const AdminPayments = React.lazy(() => import('./Pages/Admin/AdminPayments'))
const AdminPaymentDetails = React.lazy(() =>
  import('./Pages/Admin/AdminPaymentDetails'),
)
const AdminUsers = React.lazy(() => import('./Pages/Admin/AdminUsers'))
const AdminUserDetails = React.lazy(() =>
  import('./Pages/Admin/AdminUserDetails'),
)

/* Legal */
const CookiePolicy = React.lazy(() => import('./Pages/CookiePolicy'))
const PrivacyPolicy = React.lazy(() => import('./Pages/PrivacyPolicy'))
const TermsAndConditions = React.lazy(() =>
  import('./Pages/TermsAndConditions'),
)

const Router = () => {
  return (
    <BrowserRouter history={history}>
      <ErrorHandler>
        <Routes>
          <Route path={routeNames.root} element={<AppLayout />}>
            {/* Dashboard, Products */}
            <Route index element={<Dashboard />} />
            <Route path={routeNames.products}>
              <Route path=":productId" element={<Product />} />
            </Route>

            {/* Authentication */}
            <Route element={<AuthLayout />}>
              <Route path={routeNames.login} element={<Login />} />
              <Route path={routeNames.signup} element={<SignUp />} />
              <Route
                path={routeNames.forgotPassword}
                element={<ForgotPassword />}
              />
              <Route
                path={routeNames.resetPassword + `/:token`}
                element={<ResetPassword />}
              />
              <Route
                path={routeNames.resendVerification}
                element={<ResendVerification />}
              />
              <Route
                path={routeNames.verifyEmail + `/:token`}
                element={<EmailVerification />}
              />
            </Route>

            {/* Purchase */}
            <Route path={routeNames.paymentResult}>
              <Route path=":status?" element={<PaymentResult />} />
            </Route>
            <Route
              path={routeNames.purchase}
              element={
                <RequireAuth from={routeNames.purchase}>
                  <PurchaseIntentList />
                </RequireAuth>
              }
            />

            {/* Account */}
            <Route
              path={routeNames.account}
              element={
                <RequireAuth from={routeNames.account}>
                  <TabLayout tabs={userTabList} />
                </RequireAuth>
              }
            >
              <Route index element={<Navigate to={routeNames.settings} />} />
              <Route path={routeNames.settings}>
                <Route index element={<Settings />} />
              </Route>
              <Route path={routeNames.contacts}>
                <Route index element={<AccountContactList />} />
                <Route path="add" element={<AccountContactEdit add={true} />} />
                <Route path={`:contactId`} element={<AccountContactEdit />} />
              </Route>
              <Route path={routeNames.billingAddresses}>
                <Route index element={<AccountBillingAddressList />} />
                <Route
                  path="add"
                  element={<AccountBillingAddressEdit add={true} />}
                />
                <Route
                  path={`:billingAddressId`}
                  element={<AccountBillingAddressEdit />}
                />
              </Route>
              <Route path={routeNames.macAddresses}>
                <Route index element={<MacAddressList />} />
              </Route>
              <Route path={routeNames.quotes}>
                <Route index element={<QuoteList />} />
              </Route>
              <Route path={routeNames.purchases}>
                <Route index element={<PurchaseList />} />
                <Route path={`:purchaseId`} element={<PurchaseList />} />
              </Route>
              <Route path={routeNames.licenses}>
                <Route index element={<LicenseList />} />
              </Route>
            </Route>

            {/* Admin */}
            <Route
              path={adminRouteNames.root}
              element={
                <RequireAuth admin>
                  <TabLayout tabs={adminTabList} />
                </RequireAuth>
              }
            >
              <Route index element={<Navigate to={adminRouteNames.quotes} />} />
              <Route path={adminRouteNames.quotes}>
                <Route index element={<AdminQuotes />} />
                <Route path=":id" element={<AdminQuoteDetails />} />
              </Route>
              <Route path={adminRouteNames.payments}>
                <Route index element={<AdminPayments />} />
                <Route path=":payment_id" element={<AdminPaymentDetails />} />
              </Route>
              <Route path={adminRouteNames.users}>
                <Route index element={<AdminUsers />} />
                <Route path=":user_id" element={<AdminUserDetails />} />
              </Route>
            </Route>

            {/* Legal */}
            <Route
              path={`${routeNames.cookiePolicy}`}
              element={<CookiePolicy />}
            />
            <Route
              path={`${routeNames.privacyPolicy}`}
              element={<PrivacyPolicy />}
            />
            <Route
              path={`${routeNames.termsAndConditions}`}
              element={<TermsAndConditions />}
            />

            {/* Errors */}
            <Route path={`${routeNames.notFound}`} element={<NotFound />} />
            <Route path={`${routeNames.forbidden}`} element={<Forbidden />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </ErrorHandler>
    </BrowserRouter>
  )
}

export default Router
