import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import {
  AdminPanelSettings,
  Hub,
  Login,
  Menu,
  Person,
  PersonAdd,
  ShoppingCart,
  Store,
} from '@mui/icons-material'
import {
  Alert,
  AppBar,
  Badge,
  Box,
  Button,
  Container,
  IconButton,
  Toolbar,
  Tooltip,
} from '@mui/material'

import Logo from '~/assets/images/logo/logo.png'
import LogoMobile from '~/assets/images/logo/logo-mobile.png'
import { adminRouteNames } from '~/constants/adminuiRouteNames'
import { routeNames } from '~/constants/uiRouteNames'
import useStoreState from '~/hooks/useStoreState'

import LogoutButton from './Auth/LogoutButton'
import Drawer from './Drawer'
import L from './Helpers/Lang'

// const LanguageChanger = props => {
//     return (
//         <React.Fragment>
//             <button className="ui button" onClick={() => props.setLocale('hu')}>hu</button>
//             <button className="ui button" onClick={() => props.setLocale('en')}>en</button>
//         </React.Fragment>
//     );
// }

// 1500x209

export const headerItems = [
  {
    id: 'products',
    label: <L term="nav.products" />,
    icon: <Store />,
    url: routeNames.root,
  },
  {
    id: 'sites',
    label: <L term="nav.sb-hub" />,
    icon: <Hub />,
    url: 'https://skybrush.io/sites',
  },
]

const Header = () => {
  const auth = useStoreState('auth')
  const { announcements } = useStoreState('config')
  const { intentCount } = useStoreState('account')

  const [isDrawerOpen, setDrawerOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setDrawerOpen((prevState) => !prevState)
  }

  const guestActions = (
    <>
      <Button
        href={`${routeNames.root}${routeNames.signup}`}
        color="inherit"
        startIcon={<PersonAdd />}
      >
        <L>nav.sign_up</L>
      </Button>

      <Button
        href={`${routeNames.root}${routeNames.login}`}
        color="inherit"
        startIcon={<Login />}
      >
        <L>nav.login</L>
      </Button>
    </>
  )

  const userActions = (
    <>
      <Tooltip title={<L term="nav.cart" />}>
        <IconButton
          href={`${routeNames.root}${routeNames.purchase}`}
          color="inherit"
        >
          <Badge
            badgeContent={intentCount}
            color="primary"
            sx={(theme) => ({
              '& .MuiBadge-badge': {
                ...{
                  light: {
                    background: theme.palette.background.default,
                    color: theme.palette.text.primary,
                  },
                }[theme.palette.mode],
              },
            })}
          >
            <ShoppingCart />
          </Badge>
        </IconButton>
      </Tooltip>

      {auth?.user?.admin && (
        <Tooltip title={<L term="nav.admin" />}>
          <IconButton href={adminRouteNames.root} color="inherit">
            <AdminPanelSettings />
          </IconButton>
        </Tooltip>
      )}

      <Tooltip
        title={
          <span>
            <L term="nav.account" /> ({auth?.user?.name})
          </span>
        }
      >
        <IconButton
          href={`${routeNames.root}${routeNames.account}`}
          color="inherit"
        >
          <Person />
        </IconButton>
      </Tooltip>

      <LogoutButton />
    </>
  )

  return (
    <AppBar
      component="nav"
      position="relative"
      elevation={1}
      sx={(theme) => ({
        color: theme.palette.text.primary,
        zIndex: theme.zIndex.appBar,
      })}
    >
      <Drawer open={isDrawerOpen} onClose={handleDrawerToggle} />
      <Container>
        <Toolbar disableGutters={true}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <Menu />
          </IconButton>

          <Box
            component={RouterLink}
            to={routeNames.root}
            sx={(theme) => ({
              height: 22,
              content: {
                xs: `url(${LogoMobile})`,
                sm: `url(${Logo})`,
              },
              ...{ dark: { filter: 'invert(100%)' } }[theme.palette.mode],
            })}
          />

          <Box sx={{ display: { xs: 'none', sm: 'block' }, px: 2 }}>
            {headerItems.map(({ id, label, icon, url }) => (
              <Button
                key={id}
                href={url}
                color="inherit"
                size="large"
                startIcon={icon}
              >
                {label}
              </Button>
            ))}
          </Box>

          <Box flex={1} />

          <Box>{auth.isSignedIn ? userActions : guestActions}</Box>
        </Toolbar>
      </Container>
      {announcements?.map(({ content, severity }) => (
        <Alert variant="filled" severity={severity}>
          {content}
        </Alert>
      ))}
    </AppBar>
  )
}

export default Header
