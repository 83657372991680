import React from 'react'
import { useDispatch } from 'react-redux'

import { LinearProgress } from '@mui/material'

// TODO: Either clean this up or use another abstraction. I just hacked this
//       together to get rid of some previously messy and duplicated code...
const withStoreState =
  (
    depender, // Produces the parameters that are needed for fetching the data
    fetcher, // Fetches the data based on the parameters provided by `depender`
    getter, // Extracts the data that is to be passed to the wrapped component
    verifier, // Makes sure that the data from `getter` is valid for rendering
    clearer, // Clears the previously fetched data when the component unmounts
  ) =>
  (Component) =>
  (...props) => {
    const dispatch = useDispatch()

    const dependencies = depender()

    React.useEffect(() => {
      dispatch(fetcher(...dependencies))
      return () => dispatch(clearer())
    }, [dispatch, ...dependencies])

    const data = getter()

    return verifier(data) ? (
      <Component {...{ ...data, ...props }} />
    ) : (
      <LinearProgress />
    )
  }

export default withStoreState
