import { RESPONSE, PRODUCTS, ACCOUNT } from '~/constants'
import API from '~/apis/sbApi'
import { APP as URLS } from '~/constants/urls'

export const getProducts = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCTS.CLEAR_PRODUCTS })
    const response = await new API().get(`${URLS.products}`)
    dispatch({ type: PRODUCTS.SET_PRODUCTS, products: response.data })
  } catch (error) {
    dispatch({ type: RESPONSE.SET_ERROR, error })
  }
}

export const getProduct = (productId) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCTS.CLEAR_PRODUCT })
    const response = await new API().get(`${URLS.products}/${productId}`)
    dispatch({ type: PRODUCTS.SET_PRODUCT, product: response.data })
  } catch (error) {
    dispatch({ type: RESPONSE.SET_ERROR, error })
  }
}

export const clearProduct = () => ({ type: PRODUCTS.CLEAR_PRODUCT })

export const getProductAssetDownloadToken =
  (assetId, withToken) => async (dispatch) => {
    try {
      dispatch({ type: PRODUCTS.DOWNLOADING_TOKEN, value: true })
      const response = await new API().get(
        `${URLS.downloadToken}?type=asset&asset_id=${assetId}`,
      )
      withToken?.call(undefined, response.data.token)
      dispatch({ type: PRODUCTS.DOWNLOADING_TOKEN, value: false })
    } catch (error) {
      dispatch({ type: RESPONSE.SET_ERROR, error })
      dispatch({ type: PRODUCTS.DOWNLOADING_TOKEN, value: false })
    }
  }

export const createPurchaseIntent =
  ({ product_id, price_factors }, afterCreated) =>
  async (dispatch) => {
    try {
      dispatch({ type: PRODUCTS.SET_PRODUCT_PENDING_STATE, pending: true })

      const response = await new API().post(`${URLS.purchaseIntent}`, {
        product_id,
        price_factors,
      })
      dispatch({ type: PRODUCTS.SET_PRODUCT_PENDING_STATE, pending: false })

      dispatch({
        type: ACCOUNT.SET_INTENT_COUNT,
        count: response.data?.intent_count,
      })

      afterCreated?.call()
    } catch (error) {
      dispatch({ type: RESPONSE.SET_ERROR, error })
      dispatch({ type: PRODUCTS.SET_PRODUCT_PENDING_STATE, pending: false })
    }
  }
