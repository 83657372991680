import React from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { CancelOutlined, CheckCircleOutlined } from '@mui/icons-material'
import { Container, Link, Typography } from '@mui/material'

import L from '~/components/Helpers/Lang'
import { routeNames } from '~/constants/uiRouteNames'

// TODO: Consistently name these! (e.g. 'success' -> 'successful')
const PaymentStatus = {
  SUCCESS: 'success',
  CANCELED: 'canceled',
}

const colorForPaymentStatus = {
  [PaymentStatus.SUCCESS]: 'success',
  [PaymentStatus.CANCELED]: 'error',
}

const iconForPaymentStatus = {
  [PaymentStatus.SUCCESS]: CheckCircleOutlined,
  [PaymentStatus.CANCELED]: CancelOutlined,
}

const labelForPaymentStatus = {
  [PaymentStatus.SUCCESS]: 'successful',
  [PaymentStatus.CANCELED]: 'canceled',
}

const linkUrlForPaymentStatus = {
  [PaymentStatus.SUCCESS]: `/${routeNames.account}/${routeNames.purchases}`,
  [PaymentStatus.CANCELED]: `/${routeNames.purchase}`,
}

const PaymentResult = () => {
  const { status } = useParams()

  if (!Object.values(PaymentStatus).includes(status)) {
    return <Navigate to="/" />
  }

  const Icon = iconForPaymentStatus[status]

  return (
    <Container
      maxWidth="sm"
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Icon
        color={colorForPaymentStatus[status]}
        sx={{ width: 128, height: 128 }}
      />
      <Typography variant="h5">
        <L term={`label.${labelForPaymentStatus[status]}-purchase`} />
      </Typography>
      <Link variant="subtitle1" href={linkUrlForPaymentStatus[status]}>
        <L term={`text.payment-${status}`} />
      </Link>
    </Container>
  )
}

export default PaymentResult
