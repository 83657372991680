import React from 'react'
import { Field, Form } from 'react-final-form'
import { useDispatch } from 'react-redux'

import { Send } from '@mui/icons-material'
import { Box, Button, Stack, Typography } from '@mui/material'
import { TextField } from 'mui-rff'

import { saveQuoteRequest } from '~/actions/quoteActions'
import { CustomNumberInput } from '~/components/Custom/NumberInput'
import Dialog from '~/components/Helpers/Dialog'
import L from '~/components/Helpers/Lang'
import useStoreState from '~/hooks/useStoreState'
import useValidator from '~/hooks/useValidator'

const GetAQuoteDialog = ({ open, onClose, product, onSuccess }) => (
  <Dialog
    open={open}
    title={<L term="title.get-a-quote" />}
    content={
      <GetAQuoteDialogContent
        product={product}
        onClose={onClose}
        onSuccess={onSuccess}
      />
    }
    titleSx={{ fontWeight: 'normal' }}
  />
)

export const GetAQuoteDialogContent = ({
  product,
  onClose,
  onSuccess,
  inline,
  onFactorChange,
}) => {
  const dispatch = useDispatch()
  const { saveQuoteIsPending } = useStoreState('quotes')
  const pfFieldPrefix = 'pf_'

  let validationRules = {
    message: 'required|string|min.string:1',
    ...Object.fromEntries(
      (product?.price_factors ?? []).map((factor) => [
        `${pfFieldPrefix}${factor.price_factor_id}`,
        `required|numeric|min.numeric:${factor.min}`,
      ]),
    ),
  }

  const validate = useValidator(validationRules)

  const submit = React.useCallback(
    ({ ...values }) => {
      const parameters = {
        product_id: product.id,
        message: values.message,
        factors: product.price_factors.map(({ price_factor_id, min }) => ({
          price_factor_id,
          current_value: values[`${pfFieldPrefix}${price_factor_id}`] ?? min,
        })),
      }

      dispatch(saveQuoteRequest(parameters, onSuccess))
    },
    [dispatch, onSuccess, product?.id, product?.price_factors],
  )

  if (!product) {
    return null
  }

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Stack spacing={1}>
            <Box>
              <Typography variant="h6">
                {product.texts?.product_name}
              </Typography>
              {product.price_factors && (
                <Box>
                  {product.price_factors.map((factor) => (
                    <Field
                      key={factor.price_factor_id}
                      name={`${pfFieldPrefix}${factor.price_factor_id}`}
                      initialValue={factor.min}
                      render={({ input: { onChange, ...rest }, meta }) => (
                        <CustomNumberInput
                          error={meta.touched && Boolean(meta.error)}
                          label={
                            <L term={`label.factor.${factor.factor.name}`} />
                          }
                          max={factor.max}
                          min={factor.min}
                          onChange={(_e, v) => {
                            onChange(v)
                            onFactorChange?.(factor.price_factor_id, v)
                          }}
                          {...rest}
                        />
                      )}
                    />
                  ))}
                </Box>
              )}
            </Box>

            {/* TODO: Use a better abstraction instead of hiding these parts */}
            {!inline && (
              <>
                <Box>
                  <Typography variant="body1" gutterBottom>
                    <L term="title.special-needs" />
                  </Typography>
                  <TextField
                    name="message"
                    label={<L term="label.get-a-quote" />}
                    multiline
                    rows={4}
                    fullWidth
                  />
                </Box>

                {/* TODO: Use <DialogActions> instead */}
                <Box display="flex" justifyContent="flex-end" gap={1}>
                  <Button onClick={onClose}>
                    <L term="button.cancel" />
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={saveQuoteIsPending}
                    endIcon={<Send />}
                  >
                    <L term="button.send-request" />
                  </Button>
                </Box>
              </>
            )}
          </Stack>
        </form>
      )}
    />
  )
}

export default GetAQuoteDialog
