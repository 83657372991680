export const routeNames = {
  root: '/',
  login: 'login',
  logout: 'logout',
  signup: 'signup',
  account: 'account',
  settings: 'settings',
  contacts: 'contacts',
  addContact: 'contacts/add',
  billingAddresses: 'billing-addresses',
  addBillingAddress: 'billing-addresses/add',
  macAddresses: 'mac-addresses',
  products: 'products',
  download: 'download',
  purchase: 'cart',
  quotes: 'quotes',
  purchases: 'purchases',
  licenses: 'licenses',

  forgotPassword: 'forgot-password',
  resendVerification: 'resend-verification',

  resetPassword: 'reset-password',
  verifyEmail: 'verify-email',
  notFound: 'not-found',
  forbidden: 'forbidden',
  cookiePolicy: 'cookie-policy',
  privacyPolicy: 'privacy-policy',
  termsAndConditions: 'terms-and-conditions',
  paymentResult: 'payment-result',
}
