import React from 'react'
import { Link } from 'react-router-dom'

import {
  Box,
  Divider,
  Drawer as MUIDrawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material'

import Logo from '~/assets/images/logo/logo.png'
import { routeNames } from '~/constants/uiRouteNames'
import useStoreState from '~/hooks/useStoreState'

import DarkModeToggle from './DarkModeToggle'
import { LegalStuff } from './Footer'
import { headerItems } from './Header'
import L from './Helpers/Lang'
import { tabList as userTabList } from './Pages/Account/AccountTabs'
import { tabList as adminTabList } from './Pages/Admin/AdminTabs'

const Drawer = (props) => {
  const auth = useStoreState('auth')

  return (
    <MUIDrawer ModalProps={{ keepMounted: true }} {...props}>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        onClick={props.onClose}
      >
        <Link to={routeNames.root}>
          <Box
            component="img"
            sx={(theme) => ({
              width: 150,
              ...(theme.palette.mode === 'dark'
                ? { filter: 'invert(100%)' }
                : undefined),
              m: 2,
            })}
            src={Logo}
          />
        </Link>

        <Divider />

        <List>
          {headerItems.map((hi) => (
            <ListItem key={hi.id} disablePadding>
              <ListItemButton component={Link} to={hi.url}>
                <ListItemIcon>{hi.icon}</ListItemIcon>
                <ListItemText primary={hi.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        {auth.user?.admin && (
          <>
            <Divider />
            <List
              subheader={
                <ListSubheader sx={{ background: 'inherit' }}>
                  <L>nav.admin</L>
                </ListSubheader>
              }
            >
              {adminTabList.map((tab) => (
                <ListItem key={tab.name} disablePadding>
                  <ListItemButton component={Link} to={tab.link}>
                    <ListItemIcon>{tab.icon}</ListItemIcon>
                    <ListItemText primary={<L>{tab.label}</L>} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </>
        )}

        {auth.isSignedIn && (
          <>
            <Divider />
            <List
              subheader={
                <ListSubheader sx={{ background: 'inherit' }}>
                  <L>nav.account</L>
                </ListSubheader>
              }
            >
              {userTabList.map((tab) => (
                <ListItem key={tab.name} disablePadding>
                  <ListItemButton component={Link} to={tab.link}>
                    <ListItemIcon>{tab.icon}</ListItemIcon>
                    <ListItemText primary={<L>{tab.label}</L>} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </>
        )}

        <Box flex={1} />

        <Box px={2} py={1}>
          <LegalStuff />
        </Box>

        <Divider />

        <DarkModeToggle style={{ marginTop: '10px', marginLeft: '10px' }} />
      </Box>
    </MUIDrawer>
  )
}

export default Drawer
