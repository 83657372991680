import { combineReducers } from 'redux'
import auth from './auth'
import init from './init'
import lang from './lang'
import config from './config'
import response from './response'
import products from './products'
import account from './account'
import intents from './intents'
import quotes from './quotes'
import admin from './admin'

export default combineReducers({
  auth,
  init,
  lang,
  config,
  response,
  products,
  account,
  intents,
  quotes,
  admin,
})
