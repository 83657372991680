import React from 'react'

import {
  RequestQuote as QuotesIcon,
  Euro as PaymentsIcon,
  Group as UsersIcon,
} from '@mui/icons-material'

import { adminRouteNames } from '~/constants/adminuiRouteNames'

export const tabList = [
  {
    name: adminRouteNames.quotes,
    link: [adminRouteNames.root, '/', adminRouteNames.quotes].join(''),
    label: 'nav.quotes',
    icon: <QuotesIcon />,
  },
  {
    name: adminRouteNames.payments,
    link: [adminRouteNames.root, '/', adminRouteNames.payments].join(''),
    label: 'nav.purchases',
    icon: <PaymentsIcon />,
  },
  {
    name: adminRouteNames.users,
    link: [adminRouteNames.root, '/', adminRouteNames.users].join(''),
    label: 'nav.users',
    icon: <UsersIcon />,
  },
]
