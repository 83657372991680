import React from 'react'
import { Field, Form } from 'react-final-form'

import { Button, Checkbox, FormControlLabel, Stack } from '@mui/material'
import { showErrorOnBlur, TextField } from 'mui-rff'

import PasswordField from '~/components/Custom/PasswordField'
import L from '~/components/Helpers/Lang'
import useValidator from '~/hooks/useValidator'

const AuthForm = ({
  defaultValues,
  emailDisabled,
  fields,
  onSubmit,
  submitDisabled,
  submitLabel,
  validationRules,
}) => (
  <Form
    initialValues={defaultValues}
    onSubmit={onSubmit}
    validate={useValidator(validationRules)}
    render={({ handleSubmit }) => (
      <form noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          {fields.includes('name') && (
            <TextField name="name" label={<L>label.name</L>} />
          )}

          {fields.includes('email') && (
            <TextField
              type="email"
              name="email"
              label={<L>label.email</L>}
              showError={showErrorOnBlur}
              disabled={emailDisabled}
            />
          )}

          {fields.includes('password') && (
            <PasswordField
              name="password"
              label={<L>label.password</L>}
              showError={showErrorOnBlur}
            />
          )}

          {fields.includes('password_confirmation') && (
            <PasswordField
              name="password_confirmation"
              label={<L>label.password_confirmation</L>}
              showError={showErrorOnBlur}
            />
          )}

          {fields.includes('remember') && (
            <Field
              name="remember"
              label={<L>label.remember</L>}
              render={({ input: { onChange, value }, label, ...rest }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      label={label}
                      checked={!!value}
                      onChange={(_event, isInputChecked) =>
                        onChange(isInputChecked)
                      }
                      {...rest}
                    />
                  }
                  label={label}
                />
              )}
            />
          )}

          <Button
            disabled={submitDisabled}
            variant="contained"
            type="submit"
            fullWidth
            sx={{ height: 48 }}
          >
            <L>{submitLabel}</L>
          </Button>
        </Stack>
      </form>
    )}
  />
)

export default AuthForm
