import React from 'react'
import { Link } from 'react-router-dom'

import { Copyright } from '@mui/icons-material'
import { Box, Container, Typography } from '@mui/material'

import { routeNames } from '~/constants/uiRouteNames'
import useStoreState from '~/hooks/useStoreState'

import DarkModeToggle from './DarkModeToggle'
import L from './Helpers/Lang'

export const LegalStuff = () => {

  return (
    <>
      <Typography
        variant="subtitle1"
        color="text.primary"
        component={Link}
        to={routeNames.privacyPolicy}
        display="block"
        py={0.5}
      >
        <L term="nav.privacy-policy" />
      </Typography>
      <Typography
        variant="subtitle1"
        color="text.primary"
        component={Link}
        to={routeNames.cookiePolicy}
        display="block"
        py={0.5}
      >
        <L term="nav.cookie-policy" />
      </Typography>
      <Typography
        variant="subtitle1"
        color="text.primary"
        component={Link}
        to={routeNames.termsAndConditions}
        display="block"
        py={0.5}
      >
        <L term="nav.terms-and-conditions" />
      </Typography>
      <Typography variant="subtitle1" color="text.secondary">
        <Copyright fontSize="small" sx={{ verticalAlign: 'text-bottom' }} />{' '}
        {new Date().getFullYear()} CollMot Robotics.
      </Typography>
    </>
  )
}

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        display: { xs: 'none', sm: 'block' },
        width: '100%',
        position: 'relative',
      }}
    >
      <Box
        sx={(theme) => ({
          position: 'absolute',
          top: 0,
          left: 0,

          width: '100%',
          height: '100%',

          background: theme.palette.action.selected,
          zIndex: -1,
        })}
      />
      <Container
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          gap: 2,

          '@media(max-width: 720px)': {
            p: 1,
            gap: 1,
          },

          height: 64,
          [`${theme.breakpoints.down('lg')} and (orientation: landscape)`]: {
            height: 56,
          },
          [theme.breakpoints.down('md')]: { height: 48 },
        })}
      >
        <LegalStuff />
        <Box flex={1} mx={-1} />
        <DarkModeToggle style={{ marginBottom: '-8px' }} />
      </Container>
    </Box>
  )
}

export default Footer
