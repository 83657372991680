import { debounce, multiply } from 'lodash-es'
import React from 'react'

import { Delete } from '@mui/icons-material'
import {
  Box,
  Card,
  CardHeader,
  IconButton,
  Link,
  Typography,
} from '@mui/material'

import { CustomNumberInput } from '~/components/Custom/NumberInput'
import L from '~/components/Helpers/Lang'
import { routeNames } from '~/constants/uiRouteNames'
import { priceFormat } from '~/utils/numberFormat'

const calculateOriginalPriceAndDiscount = (
  unit,
  factorAmounts,
  volume_discount,
) => {
  const activeLevel = volume_discount?.levels?.find((l) =>
    l.conditions.every((c) => factorAmounts[c.price_factor_id] >= c.value),
  )

  const productOfFactors = Object.values(factorAmounts).reduce(multiply, 1)

  const originalPrice = productOfFactors * unit

  const discount = activeLevel
    ? (() => {
        switch (volume_discount.type) {
          case 'percent': {
            return originalPrice * (Number(activeLevel.amount) / 100)
          }

          case 'total': {
            return Number(activeLevel.amount)
          }

          case 'unit': {
            return productOfFactors * Number(activeLevel.amount)
          }

          default: {
            return 0
          }
        }
      })()
    : 0

  return {
    originalPrice,
    discount,
  }
}

const PurchaseIntent = ({
  intent,
  onPriceChange,
  hiddenTitle = false,
  deleteEnabled = true,
  handleDelete,
  handleUpdate,
  handleUpdateTimeoutMs = 500,
  editDisabled,
}) => {
  const {
    discount: quoteDiscount = 0,
    product,
    price_factors,
    price: quotePrice,
  } = intent

  const [factorAmounts, setFactorAmounts] = React.useState(
    Object.fromEntries(
      price_factors.map((pf) => [pf.price_factor_id, pf.current_value]),
    ),
  )

  const isFromQuote = Boolean(intent.quote_request_id)

  const { originalPrice, discount } = React.useMemo(
    () =>
      isFromQuote
        ? {
            originalPrice: quotePrice,
            discount: quoteDiscount,
          }
        : calculateOriginalPriceAndDiscount(
            product.price?.price,
            factorAmounts,
            product.volume_discount,
          ),
    [factorAmounts, isFromQuote, product, quoteDiscount, quotePrice],
  )

  const discountedPrice = React.useMemo(
    () => originalPrice - discount,
    [originalPrice, discount],
  )

  const updateFactorAmounts = React.useCallback(
    debounce(
      (fas) =>
        handleUpdate(intent.id, {
          factors: Object.entries(fas).map(
            ([price_factor_id, current_value]) => ({
              price_factor_id,
              current_value,
            }),
          ),
        }),
      handleUpdateTimeoutMs,
    ),
    [handleUpdate, intent],
  )

  React.useEffect(() => {
    updateFactorAmounts(factorAmounts)
  }, [factorAmounts, updateFactorAmounts])

  React.useEffect(
    () => onPriceChange?.({ intent_id: intent.id, price: discountedPrice }),
    [onPriceChange, intent, discountedPrice],
  )

  const handleFactorAmountChange = (event, value) => {
    const price_factor_id = Number(
      event.target.closest('.MuiNumberInput-root').dataset.priceFactorId,
    )
    setFactorAmounts((fa) => ({ ...fa, [price_factor_id]: value }))
  }

  const handleDeleteIntent = React.useCallback(
    () => (deleteEnabled ? handleDelete(intent.id) : false),
    [deleteEnabled, handleDelete, intent.id],
  )

  return (
    <Card sx={{ width: '100%' }}>
      {!hiddenTitle && (
        <CardHeader
          title={
            <Link
              color="text.primary"
              underline="none"
              href={`/${routeNames.products}/${product.id}`}
            >
              {product.texts.product_name}
            </Link>
          }
          action={
            <IconButton onClick={handleDeleteIntent}>
              <Delete />
            </IconButton>
          }
        />
      )}
      <Box
        minHeight={100}
        p={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={1}
      >
        <Box
          flex={1}
          height="100%"
          display="flex"
          alignItems="center"
          flexWrap="wrap"
        >
          {factorAmounts &&
            product.price_factors.map((factor) => (
              <CustomNumberInput
                key={factor.price_factor_id}
                data-price-factor-id={`${factor.price_factor_id}`}
                label={<L term={`label.factor.${factor.factor.name}`} />}
                sx={{ flexShrink: 0, flexGrow: 1, maxWidth: 200 }}
                disabled={editDisabled}
                min={factor.min}
                max={factor.max}
                value={factorAmounts[factor.price_factor_id]}
                onChange={handleFactorAmountChange}
              />
            ))}
        </Box>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          textAlign="right"
        >
          {Boolean(discount) && (
            <>
              <Typography>{priceFormat(originalPrice)}</Typography>
              <Typography color="success.main">
                - {priceFormat(discount)}
              </Typography>
            </>
          )}
          <Typography variant="h5" minWidth={128}>
            {priceFormat(discountedPrice)}
          </Typography>
        </Box>
      </Box>
    </Card>
  )
}

export default PurchaseIntent
