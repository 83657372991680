import React from 'react'
import Dialog from '~/components/Helpers/Dialog'
import L from '~/components/Helpers/Lang'
import { Box } from '@mui/material'

const GetAQuoteSuccessDialog = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      // title={<L term="title.quote-request-sent" />}
      content={
        <Box>
          <L term="content.quote-request-sent" />
        </Box>
      }
      buttons={[
        {
          variant: 'contained',
          onClick: onClose,
          label: <L term="button.got_it" />,
        },
      ]}
    />
  )
}

export default GetAQuoteSuccessDialog
