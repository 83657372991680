import React from 'react'
import { useTheme } from '@mui/material/styles'

import 'dark-mode-toggle'
import '~/assets/dark-mode-toggle-slider.css'

import { SetPaletteModeContext } from '~/hooks/useNewTheme'

const DarkModeToggle = (props) => {
  const setPaletteMode = React.useContext(SetPaletteModeContext)
  const theme = useTheme()

  const ref = React.useRef()

  React.useEffect(() => {
    const darkModeToggle = ref.current
    if (darkModeToggle) {
      // HACK: Delay applying the stored / inferred mode to the switch.
      setTimeout(() => {
        darkModeToggle.mode = theme.palette.mode
      }, 0)
    }
  }, [theme.palette.mode])

  const handleChange = React.useCallback(
    (e) => {
      localStorage.setItem('palette', e.detail.colorScheme)
      setPaletteMode(e.detail.colorScheme)
    },
    [setPaletteMode],
  )

  React.useEffect(() => {
    const darkModeToggle = ref.current

    if (darkModeToggle) {
      darkModeToggle.addEventListener('colorschemechange', handleChange)
    }

    return () => {
      darkModeToggle.removeEventListener('colorschemechange', handleChange)
    }
  }, [handleChange])

  return (
    <dark-mode-toggle ref={ref} class="slider" {...props}></dark-mode-toggle>
  )
}

export default DarkModeToggle
