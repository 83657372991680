import React from 'react'
import { useDispatch } from 'react-redux'

import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { initialize } from '~/actions/initActions'
import { SetPaletteModeContext, useNewTheme } from '~/hooks/useNewTheme'
import useStoreState from '~/hooks/useStoreState'

import Analytics from './Analytics'
import Router from './AppRouter'
import FullPageLoader from './Helpers/FullPageLoader'

// import 'dayjs/locale/hu';

const App = (/* { init } */) => {
  const dispatch = useDispatch()
  const { done } = useStoreState('init')

  const { theme, setPaletteMode } = useNewTheme()

  const [fontsReady, setFontsReady] = React.useState(false)
  const [splashEntered, setSplashEntered] = React.useState(false)
  const [splashVisible, setSplashVisible] = React.useState(true)

  React.useEffect(() => {
    document.fonts.ready.then(() => {
      setFontsReady(true)
    })
  }, [setFontsReady])

  React.useEffect(() => {
    dispatch(initialize())
  }, [dispatch])

  const isHomePage = window.location.pathname === '/'

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} /*adapterLocale='hu'*/>
      <SetPaletteModeContext.Provider value={setPaletteMode}>
        <ThemeProvider theme={theme}>
          <Analytics />
          <CssBaseline />
          {/*
           * TODO: Use `React.Suspense` properly by making the app suspend while
           *       waiting for `init` and using `FullPageLoader` as fallback
           */}
          {splashVisible && (
            <FullPageLoader
              in={fontsReady && !done}
              onEntered={() => {
                setSplashEntered(true)
              }}
              onExited={() => {
                setSplashVisible(false)
              }}
            />
          )}
          {splashEntered && (done || isHomePage) && <Router />}
        </ThemeProvider>
      </SetPaletteModeContext.Provider>
    </LocalizationProvider>
  )
}

export default App
