import React from 'react'
import Dialog from '~/components/Helpers/Dialog'
import L from '~/components/Helpers/Lang'
import { Box, Button } from '@mui/material'
// import { Send as SendIcon } from '@mui/icons-material';
// import { Field, Form } from 'react-final-form';
// import useValidator from '~/hooks/useValidator';
// import { useDispatch } from 'react-redux';
// import { saveQuoteRequest } from '~/actions/quoteActions';
// import useStoreState from '~/hooks/useStoreState';

const AddedToCartDialog = ({ open, title, onClose, onSuccess }) => {
  return (
    <Dialog
      open={open}
      title={title}
      content={
        <AddedToCartDialogContent onClose={onClose} onSuccess={onSuccess} />
      }
      titleSx={{ fontWeight: 400 }}
      maxWidth="xs"
    />
  )
}

const AddedToCartDialogContent = ({ onClose, onSuccess }) => {
  return (
    <Box
      sx={{
        pt: 2,
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Button variant="contained" onClick={onClose}>
        <L term="button.continue-shopping" />
      </Button>
      <Button variant="contained" onClick={onSuccess}>
        <L term="button.go-to-cart" />
      </Button>
    </Box>
  )
}

export default AddedToCartDialog
