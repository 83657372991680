export const ADMIN = {
  SET_PENDING: 'ADMIN@SET_PENDING',
}

export const QUOTES = {
  CLEAR_QUOTES: 'ADMIN_QUOTES@CLEAR_QUOTES',
  CLEAR_QUOTE: 'ADMIN_QUOTES@CLEAR_QUOTE',
  SET_QUOTES: 'ADMIN_QUOTES@SET_QUOTES',
  SET_QUOTE: 'ADMIN_QUOTES@SET_QUOTE',
  SET_QUOTE_CUSTOMER_PRICE: 'ADMIN_QUOTES@SET_QUOTE_CUSTOMER_PRICE',
  SET_QUOTE_PRICE_DISCOUNT: 'ADMIN_QUOTES@SET_QUOTE_PRICE_DISCOUNT',
  SET_QUOTE_ANSWER: 'ADMIN_QUOTES@SET_QUOTE_ANSWER',
}

export const PAYMENTS = {
  CLEAR_PAYMENTS: 'ADMIN_QUOTES@CLEAR_PAYMENTS',
  CLEAR_PAYMENT: 'ADMIN_QUOTES@CLEAR_PAYMENT',
  SET_PAYMENTS: 'ADMIN_QUOTES@SET_PAYMENTS',
  SET_PAYMENT: 'ADMIN_QUOTES@SET_PAYMENT',
  SET_USERS: 'ADMIN_QUOTES@SET_USERS',
  CLEAR_USERS: 'ADMIN_QUOTES@CLEAR_USERS',
  CLEAR_USER: 'ADMIN_QUOTES@CLEAR_USER',
  SET_USER: 'ADMIN_QUOTES@SET_USER',
}
