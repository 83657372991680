export const apiURL =
  process.env.REACT_APP_API_URL ??
  `${window.location.protocol}//api.${window.location.host}`

const apiRoutePrefix = '/api'

export const INIT = {
  csrfCookie: '/sanctum/csrf-cookie',
  getUser: `${apiRoutePrefix}/user`,
  initialize: `${apiRoutePrefix}/initialize`,
}

export const LANG = {
  locale: '/api/locale',
}

export const APP = {
  forgotPassword: `${apiRoutePrefix}/forgot-password`,
  resetPassword: `${apiRoutePrefix}/reset-password`,
  verifyEmail: `${apiRoutePrefix}/email/verify`,
  verificationNotification: `${apiRoutePrefix}/email/verification-notification`,
  changePassword: `${apiRoutePrefix}/user/password`,
  deleteProfile: `${apiRoutePrefix}/user/delete`,
  deliveryAddress: `${apiRoutePrefix}/user/delivery-address`,
  bankAccountDetails: `${apiRoutePrefix}/user/bank-account-details`,
  phoneDetails: `${apiRoutePrefix}/user/phone`,
  products: `${apiRoutePrefix}/products`,
  downloadToken: `${apiRoutePrefix}/download-token`,
  purchaseIntent: `${apiRoutePrefix}/purchase-intent`,
  purchaseIntents: `${apiRoutePrefix}/purchase-intents`,
  quoteRequest: `${apiRoutePrefix}/quote-request`,
  startPayment: `${apiRoutePrefix}/start-payment`,
  // TODO: Maybe rename to `pending-payment(s)`? (Open can be a verb...)
  openPayments: `${apiRoutePrefix}/account/open-payments`,
  cancelPayment: `${apiRoutePrefix}/cancel-payment`,

  highlightProduct: `${apiRoutePrefix}/user/highlight-product`,
  profile: `${apiRoutePrefix}/user/:userId/profile`,
  userSettings: `${apiRoutePrefix}/user/settings`,
  dashboard: `${apiRoutePrefix}/dashboard`,
  tos: `${apiRoutePrefix}/tos`,
  optOut: `${apiRoutePrefix}/opt-out`,
}

export const AUTH = {
  login: `${apiRoutePrefix}/login`,
  logout: `${apiRoutePrefix}/logout`,
  register: `${apiRoutePrefix}/register`,
}

export const ACCOUNT = {
  profileInformation: `${apiRoutePrefix}/user/profile-information`,
  password: `${apiRoutePrefix}/user/password`,
  contacts: `${apiRoutePrefix}/account/contacts`,
  billingAddresses: `${apiRoutePrefix}/account/billing-addresses`,
  macAddresses: `${apiRoutePrefix}/account/mac-addresses`,
  quotes: `${apiRoutePrefix}/account/quotes`,
  purchases: `${apiRoutePrefix}/account/purchases`,
  licenses: `${apiRoutePrefix}/account/licenses`,
  countries: `${apiRoutePrefix}/countries`,
}
