import React from 'react'

import {
  ManageAccounts as SettingsIcon,
  Contacts as ContactsIcon,
  Receipt as BillingAddressesIcon,
  Devices as MacAddressesIcon,
  RequestQuote as QuotesIcon,
  Inventory as PurchasesIcon,
  Key as LicensesIcon,
} from '@mui/icons-material'

import { routeNames } from '~/constants/uiRouteNames'

const accountRoute = `${routeNames.root}${routeNames.account}`

export const tabList = [
  {
    name: routeNames.settings,
    link: `${accountRoute}/${routeNames.settings}`,
    label: 'nav.account-settings',
    icon: <SettingsIcon />,
  },
  {
    name: routeNames.contacts,
    link: `${accountRoute}/${routeNames.contacts}`,
    label: 'nav.contacts',
    icon: <ContactsIcon />,
  },
  {
    name: routeNames.billingAddresses,
    link: `${accountRoute}/${routeNames.billingAddresses}`,
    label: 'nav.billing-addresses',
    icon: <BillingAddressesIcon />,
  },
  {
    name: routeNames.macAddresses,
    link: `${accountRoute}/${routeNames.macAddresses}`,
    label: 'nav.mac-addresses',
    icon: <MacAddressesIcon />,
  },
  {
    name: routeNames.quotes,
    link: `${accountRoute}/${routeNames.quotes}`,
    label: 'nav.your-quotes',
    icon: <QuotesIcon />,
  },
  {
    name: routeNames.purchases,
    link: `${accountRoute}/${routeNames.purchases}`,
    label: 'nav.your-purchases',
    icon: <PurchasesIcon />,
  },
  {
    name: routeNames.licenses,
    link: `${accountRoute}/${routeNames.licenses}`,
    label: 'nav.your-licenses',
    icon: <LicensesIcon />,
  },
]
