import React from 'react'
import { useLocation } from 'react-router-dom'

import { Typography } from '@mui/material'

import L from '~/components/Helpers/Lang'

import { BackToShopButton, ErrorContactUs, ErrorLayout } from '.'

const Forbidden = () => {
  const { state } = useLocation()
  const from = state?.from?.pathname ?? window.location.pathname

  return (
    <ErrorLayout>
      <Typography variant="h4" color="error">
        <L term="label.forbidden" />
      </Typography>
      <ErrorContactUs code={403} path={from} />
      <BackToShopButton />
    </ErrorLayout>
  )
}

export default Forbidden
