import { Grid, LinearProgress } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { getProducts } from '~/actions/productActions'
import useStoreState from '~/hooks/useStoreState'
import DashboardProductListItem from './DashboardProductListItem'

const DashboardProductList = () => {
  const { productList } = useStoreState('products')
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(getProducts())
  }, [dispatch])

  if (!productList) {
    return <LinearProgress />
  }

  return (
    <Grid container spacing={2}>
      {productList
        .filter((p) => p.enabled && !p.deleted)
        .map((product) => (
          <DashboardProductListItem key={product.id} product={product} />
        ))}
    </Grid>
  )
}

export default DashboardProductList
