import { QUOTES, PAYMENTS, ADMIN } from '~/constants/adminConstants'

const initialState = {
  quotes: undefined,
  quote: undefined,
  payments: undefined,
  users: undefined,
  usersPagination: undefined,
  payment: undefined,
  paymentsPagination: undefined,
  pending: false,
}

const admin = (state = initialState, action) => {
  switch (action.type) {
    case QUOTES.CLEAR_QUOTES:
      return { ...state, quotes: initialState.quotes }
    case QUOTES.SET_QUOTES:
      return {
        ...state,
        quotes: action.quotes,
        quotesPagination: action.pagination,
      }
    case QUOTES.CLEAR_QUOTE:
      return { ...state, quote: initialState.quote }
    case QUOTES.SET_QUOTE:
      return { ...state, quote: action.quote }
    case QUOTES.SET_QUOTE_CUSTOMER_PRICE:
      return {
        ...state,
        quote: { ...state.quote, customer_price: action.customer_price },
      }
    case QUOTES.SET_QUOTE_PRICE_DISCOUNT:
      return {
        ...state,
        quote: { ...state.quote, price_discount: action.price_discount },
      }
    case QUOTES.SET_QUOTE_ANSWER:
      return {
        ...state,
        quote: { ...state.quote, answer_text: action.answer_text },
      }

    case PAYMENTS.CLEAR_PAYMENTS:
      return { ...state, payments: initialState.payments }
    case PAYMENTS.CLEAR_USERS:
      return { ...state, users: initialState.users }
    case PAYMENTS.SET_PAYMENTS:
      return {
        ...state,
        payments: action.payments,
        paymentsPagination: action.pagination,
      }
    case PAYMENTS.SET_USERS:
      return {
        ...state,
        users: action.users,
        usersPagination: action.pagination,
      }
    case PAYMENTS.CLEAR_PAYMENT:
      return { ...state, payment: initialState.payment }
    case PAYMENTS.SET_PAYMENT:
      return { ...state, payment: action.payment }
    case PAYMENTS.CLEAR_USER:
      return { ...state, user: initialState.user }
    case PAYMENTS.SET_USER:
      return { ...state, user: action.user }

    case ADMIN.SET_PENDING:
      return { ...state, pending: action.value }
    default:
      return state
  }
}

export default admin
