import { PURCHASE_INTENTS } from '~/constants'

const initialState = {
  intentList: undefined,
  paying: false,
  allowedToPay: false,
  openPayments: undefined,
  vatPercent: undefined,
}

const products = (state = initialState, action) => {
  switch (action.type) {
    case PURCHASE_INTENTS.CLEAR_INTENTS:
      return { ...state, intentList: initialState.intentList }
    case PURCHASE_INTENTS.SET_INTENTS:
      return { ...state, intentList: action.intents }
    case PURCHASE_INTENTS.PAYING:
      return { ...state, paying: action.value }
    case PURCHASE_INTENTS.SET_USER_ALLOWED_TO_PAY:
      return { ...state, allowedToPay: action.allowed }
    case PURCHASE_INTENTS.CLEAR_OPEN_PAYMENTS:
      return { ...state, openPayments: initialState.openPayments }
    case PURCHASE_INTENTS.SET_OPEN_PAYMENTS:
      return { ...state, openPayments: action.openPayments }
    case PURCHASE_INTENTS.CLEAR_VAT_PERCENT:
      return { ...state, vatPercent: initialState.vatPercent }
    case PURCHASE_INTENTS.SET_VAT_PERCENT:
      return { ...state, vatPercent: action.vatPercent }
    default:
      return state
  }
}

export default products
