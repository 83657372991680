import axios from 'axios'

import { apiURL } from '~/constants/urls'
import { responseAdapter as adapter } from '~/utils'

class API {
  constructor() {
    this.axios = axios.create({
      baseURL: apiURL,
      withCredentials: true,
      timeout: process.env.REACT_APP_REQUEST_TIMEOUT,
    })

    this.setupInterceptors()
    return this.axios
  }

  setupInterceptors() {
    this.axios.interceptors.request.use(
      (config) => {
        return config
      },
      (requestError) => {
        return Promise.reject(requestError)
      },
    )
    this.axios.interceptors.response.use(
      (_response) => {
        return adapter({ success: _response })
      },
      (error) => {
        return Promise.reject(adapter({ error: error.response }))
      },
    )
  }
}

export default API
