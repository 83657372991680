import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { Alert, Container, Link, Typography } from '@mui/material'

import L from '~/components/Helpers/Lang'
import { routeNames } from '~/constants/uiRouteNames'
import useStoreState from '~/hooks/useStoreState'

const AuthLayout = () => {
  const auth = useStoreState('auth')
  const navigate = useNavigate()
  const location = useLocation()
  if (auth.isSignedIn) {
    const pendingAction = JSON.parse(
      window.localStorage.getItem('pendingAction'),
    )

    if (pendingAction?.target?.pathname) {
      navigate(pendingAction?.target?.pathname)
    } else if (location?.state?.from?.pathname) {
      navigate(location?.state?.from?.pathname)
    } else {
      navigate(routeNames.root)
    }
  }

  return (
    <Container maxWidth="sm">
      <Outlet />
    </Container>
  )
}

export const AuthTitle = ({ title }) => (
  <Typography variant="h4" align="center" my={5}>
    {title}
  </Typography>
)

export const AuthInfo = () => (
  <Alert variant="outlined" severity="info" sx={{ mt: -3, mb: 3 }}>
    <L term="text.skybrush_account_auth" partial={0} />
    <Link href="https://account.skybrush.io">
      <L term="text.skybrush_account_auth" partial={1} />
    </Link>
    <L term="text.skybrush_account_auth" partial={2} />
  </Alert>
)

export default AuthLayout
