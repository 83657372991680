import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Logout } from '@mui/icons-material'
import { CircularProgress, IconButton, Tooltip } from '@mui/material'

import L from '~/components/Helpers/Lang'
import { AUTH, RESPONSE } from '~/constants'
import { routeNames } from '~/constants/uiRouteNames'
import { AUTH as URLS } from '~/constants/urls'
import { usePost } from '~/hooks/useApi'

const LogoutButton = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [logout, { isPending }] = usePost(URLS.logout)

  return (
    <Tooltip title={<L>nav.logout</L>}>
      <IconButton
        color="inherit"
        onClick={() => {
          logout(
            {},
            () => {
              dispatch({ type: AUTH.UNAUTHENTICATED })
              navigate(routeNames.root)
            },
            (error) => {
              dispatch({ type: RESPONSE.SET_ERROR, error })
            },
          )
        }}
      >
        {isPending ? (
          <CircularProgress color="inherit" size={24} />
        ) : (
          <Logout />
        )}
      </IconButton>
    </Tooltip>
  )
}

export default LogoutButton
