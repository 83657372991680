// import { routeNames } from './uiRouteNames';

export const INIT = {
  INITIALIZING: 'INIT@INITIALIZING',
  INITIALIZED: 'INIT@INITIALIZED',
  UNINITIALIZED: 'INIT@UNINITIALIZED',
}

export const AUTH = {
  SET_CSRF_COOKIE: 'AUTH@SET_CSRF_COOKIE',
  LOGGED_IN: 'AUTH@LOGGED_IN',
  AUTHENTICATED: 'AUTH@AUTHENTICATED',
  UNAUTHENTICATED: 'AUTH@UNAUTHENTICATED',
  SIGNED_UP: 'AUTH@SIGNED_UP',
  LOGGED_OUT: 'AUTH@LOGGED_OUT',
}

export const RESPONSE = {
  SET_SUCCESS: 'RESPONSE@SET_SUCCESS',
  SET_ERROR: 'RESPONSE@SET_ERROR',
  CLEAR: 'RESPONSE@CLEAR',
}

export const APP = {
  SET_INTENTED_ROUTE: 'APP@SET_INTENTED_ROUTE',
  UNSET_INTENTED_ROUTE: 'APP@UNSET_INTENTED_ROUTE',

  PASSWORD_RESET_LINK_SENT_SUCCESS: 'APP@PASSWORD_RESET_LINK_SENT_SUCCESS',
  PASSWORD_RESET_LINK_SENT_FAILED: 'APP@PASSWORD_RESET_LINK_SENT_FAILED',
  PASSWORD_RESET_SUCCESS: 'APP@PASSWORD_RESET_SUCCESS',
  PASSWORD_RESET_FAILED: 'APP@PASSWORD_RESET_FAILED',

  REDIRECTED: 'APP@REDIRECTED',

  VERIFYING_EMAIL: 'APP@VERIFYING_EMAIL',
  EMAIL_VERIFICATION_SUCCESS: 'APP@EMAIL_VERIFICATION_SUCCESS',
  EMAIL_VERIFICATION_FAILED: 'APP@EMAIL_VERIFICATION_FAILED',
}

export const CONFIG = {
  SET_CONFIG: 'CONFIG@SET_CONFIG',
}

export const ACCOUNT = {
  SET_REQUEST_IS_PENDING: 'ACCOUNT@SET_REQUEST_IS_PENDING',
  CLEAR_PROFILE_INFORMATION: 'ACCOUNT@CLEAR_PROFILE_INFORMATION',
  SET_PROFILE_INFORMATION: 'ACCOUNT@SET_PROFILE_INFORMATION',
  CLEAR_CONTACTS: 'ACCOUNT@CLEAR_CONTACTS',
  CLEAR_CONTACT: 'ACCOUNT@CLEAR_CONTACT',
  SET_CONTACTS: 'ACCOUNT@SET_CONTACTS',
  SET_CONTACT: 'ACCOUNT@SET_CONTACT',
  REMOVE_CONTACT: 'ACCOUNT@REMOVE_CONTACT',
  CLEAR_BILLING_ADDRESSES: 'ACCOUNT@CLEAR_BILLING_ADDRESSES',
  SET_BILLING_ADDRESSES: 'ACCOUNT@SET_BILLING_ADDRESSES',
  CLEAR_BILLING_ADDRESS: 'ACCOUNT@CLEAR_BILLING_ADDRESS',
  SET_BILLING_ADDRESS: 'ACCOUNT@SET_BILLING_ADDRESS',
  REMOVE_BILLING_ADDRESS: 'ACCOUNT@REMOVE_BILLING_ADDRESS',
  CLEAR_MAC_ADDRESSES: 'ACCOUNT@CLEAR_MAC_ADDRESSES',
  SET_MAC_ADDRESSES: 'ACCOUNT@SET_MAC_ADDRESSES',
  REMOVE_MAC_ADDRESS: 'ACCOUNT@REMOVE_MAC_ADDRESS',
  CLEAR_QUOTES: 'ACCOUNT@CLEAR_QUOTES',
  SET_QUOTES: 'ACCOUNT@SET_QUOTES',
  CLEAR_PURCHASES: 'ACCOUNT@CLEAR_PURCHASES',
  SET_PURCHASES: 'ACCOUNT@SET_PURCHASES',
  DOWNLOADING_TOKEN: 'ACCOUNT@DOWNLOADING_TOKEN',
  SET_INTENT_COUNT: 'ACCOUNT@SET_INTENT_COUNT',
  CLEAR_LICENSES: 'ACCOUNT@CLEAR_LICENSES',
  SET_LICENSES: 'ACCOUNT@SET_LICENSES',
  CLEAR_COUNTRIES: 'ACCOUNT@CLEAR_COUNTRIES',
  SET_COUNTRIES: 'ACCOUNT@SET_COUNTRIES',
}

export const LANG = {
  SET: 'LANG@SET',
  LOCALE_SET: 'LANG@LOCALE_SET',
}

export const PRODUCTS = {
  SET_PRODUCTS: 'PRODUCTS@SET_PRODUCTS',
  CLEAR_PRODUCTS: 'PRODUCTS@CLEAR_PRODUCTS',
  SET_PRODUCT: 'PRODUCTS@SET_PRODUCT',
  CLEAR_PRODUCT: 'PRODUCTS@CLEAR_PRODUCT',
  SET_PRODUCT_PENDING_STATE: 'PRODUCTS@SET_PRODUCT_PENDING_STATE',
  DOWNLOADING_TOKEN: 'PRODUCTS@DOWNLOADING_TOKEN',
}

export const PURCHASE_INTENTS = {
  SET_INTENTS: 'PURCHASE_INTENTS@SET_INTENTS',
  CLEAR_INTENTS: 'PURCHASE_INTENTS@CLEAR_INTENTS',
  PAYING: 'PURCHASE_INTENTS@PAYING',
  SET_USER_ALLOWED_TO_PAY: 'APP@SET_USER_ALLOWED_TO_PAY',
  SET_OPEN_PAYMENTS: 'PURCHASE_OPEN_PAYMENTS@SET_OPEN_PAYMENTS',
  CLEAR_OPEN_PAYMENTS: 'PURCHASE_OPEN_PAYMENTS@CLEAR_OPEN_PAYMENTS',
  SET_VAT_PERCENT: 'PURCHASE_INTENTS@SET_VAT_PERCENT',
  CLEAR_VAT_PERCENT: 'PURCHASE_INTENTS@CLEAR_VAT_PERCENT',
}

export const QUOTES = {
  SAVE_QUOTE_IS_PENDING: 'QUOTES@SAVE_QUOTE_IS_PENDING',
}
