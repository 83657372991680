import React from 'react'
import { useDispatch } from 'react-redux'

import { Box } from '@mui/material'

import { routeNames } from '~/constants/uiRouteNames'
import { apiURL } from '~/constants/urls'

export const useDownloadToken = () => {
  const dispatch = useDispatch()

  const [downloadUrl, setDownloadUrl] = React.useState(undefined)
  const [downloadLink, setDownloadLink] = React.useState(undefined)

  React.useEffect(() => {
    if (downloadLink) {
      downloadLink.click()
      setDownloadUrl(undefined)
      setDownloadLink(undefined)
    }
  }, [downloadLink])

  const withToken = React.useCallback((token) => {
    setDownloadUrl(`${apiURL}/${routeNames.download}/${token}`)
  }, [])

  const getDownloadToken = React.useCallback(
    (action, params) => {
      dispatch(action.apply(null, [params, withToken]))
    },
    [dispatch, withToken],
  )

  const DownloadComponent = downloadUrl ? (
    <Box
      component="a"
      sx={{ display: 'none' }}
      href={downloadUrl}
      ref={(ref) => setDownloadLink(ref)}
    ></Box>
  ) : null

  return {
    getDownloadToken,
    DownloadComponent,
  }
}
