import React from 'react'

import splash from '~/assets/splash.webm'
import { Box, Fade, Typography } from '@mui/material'

const FullPageLoader = (props) => {
  return (
    <Fade timeout={{ appear: 0, enter: 0, exit: 500 }} {...props}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',

          position: 'fixed',
          top: 0,
          left: 0,

          width: '100vw',
          height: '100vh',

          background: theme.palette.background.default,

          zIndex: theme.zIndex.drawer + 1, // same as `Backdrop` by default
        })}
      >
        <Typography sx={{ fontFamily: 'Fira Sans' }} variant="h2">
          Skybrush Shop
        </Typography>
        <video
          autoPlay
          muted
          style={{
            width: 256,
            height: 256,
            borderTopLeftRadius: '50%',
          }}
        >
          <source src={splash} />
        </video>
      </Box>
    </Fade>
  )
}

export default FullPageLoader
