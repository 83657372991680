import { INIT } from '~/constants'

const INITIAL_STATE = {
  done: false,
  pending: false,
}

const init = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INIT.INITIALIZING:
      return {
        ...state,
        pending: true,
      }
    case INIT.INITIALIZED:
      return {
        done: true,
        pending: false,
      }

    case INIT.UNINITIALIZED:
      return {
        done: false,
        pending: false,
      }

    default:
      return state
  }
}

export default init
