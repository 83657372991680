import React from 'react'
import { Card, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import { CardActionArea } from '@mui/material'
import { Link } from 'react-router-dom'
import { routeNames } from '~/constants/uiRouteNames'

const DashboardProductListItem = ({ product }) => (
  <Grid item xs={12} sm={6} md={4}>
    <Card sx={{ height: '100%' }}>
      <CardActionArea
        component={Link}
        to={`${routeNames.root}${routeNames.products}/${
          product.slugs?.[0] ?? product.id
        }`}
        sx={{ height: '100%' }}
      >
        <CardMedia
          sx={{ height: 240 }}
          image={`${product?.images?.[0]?.url}/${product?.images?.[0]?.file_name}`}
          title={product?.texts?.product_name}
        />
        <CardContent>
          <Typography
            gutterBottom
            color="primary"
            variant="h5"
            sx={{ fontWeight: 300 }}
          >
            {product?.texts?.product_name}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ pr: 10 }}>
            {product?.texts?.product_tagline}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  </Grid>
)

export default DashboardProductListItem
