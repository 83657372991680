import { RESPONSE, QUOTES } from '~/constants'
import API from '~/apis/sbApi'
import { APP as URLS } from '~/constants/urls'

export const saveQuoteRequest = (parameters, onSuccess) => async (dispatch) => {
  try {
    dispatch({ type: QUOTES.SAVE_QUOTE_IS_PENDING, value: true })
    await new API().post(`${URLS.quoteRequest}`, parameters)
    dispatch({ type: QUOTES.SAVE_QUOTE_IS_PENDING, value: false })
    onSuccess?.call()
  } catch (error) {
    dispatch({ type: QUOTES.SAVE_QUOTE_IS_PENDING, value: false })
    dispatch({ type: RESPONSE.SET_ERROR, error })
  }
}
