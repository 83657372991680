import useValidationDict from './useValidationDict'
import { Validator } from '~/utils/validator'

const useValidator = (validationRules) => {
  const validationDict = useValidationDict()
  const validate = new Validator(validationDict).make(validationRules)

  return validate
}

export default useValidator
