import { PRODUCTS } from '~/constants'

const initialState = {
  productList: undefined,
  product: undefined,
  productIsInPendingState: false,
  downloadingToken: false,
}

const products = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCTS.CLEAR_PRODUCTS:
      return { ...state, productList: initialState.productList }
    case PRODUCTS.SET_PRODUCTS:
      return { ...state, productList: action.products }
    case PRODUCTS.CLEAR_PRODUCT:
      return { ...state, product: initialState.product }
    case PRODUCTS.SET_PRODUCT:
      return { ...state, product: action.product }
    case PRODUCTS.SET_PRODUCT_PENDING_STATE:
      return { ...state, productIsInPendingState: action.pending }
    case PRODUCTS.DOWNLOADING_TOKEN:
      return { ...state, downloadingToken: action.value }
    default:
      return state
  }
}

export default products
