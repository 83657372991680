import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { routeNames } from '~/constants/uiRouteNames'
import useStoreState from '~/hooks/useStoreState'

const RequireAuth = ({ admin, children }) => {
  const auth = useStoreState('auth')
  const location = useLocation()

  if (!auth.isSignedIn) {
    return (
      <Navigate
        to={`${routeNames.root}${routeNames.login}`}
        state={{ from: location }}
      />
    )
  }

  if (admin && !auth?.user?.admin) {
    return (
      <Navigate
        to={`${routeNames.root}${routeNames.forbidden}`}
        state={{ from: location }}
      />
    )
  }

  return children
}

export default RequireAuth
