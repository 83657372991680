import React from 'react'
import { createRoot } from 'react-dom/client'
import { createStore, compose, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import reducers from './reducers'
import { isDev } from './utils/env'

import App from './components/App'

import '@fontsource/fira-sans/latin.css'
import '@fontsource/fira-sans/latin-ext.css'
import '@fontsource/fira-mono/latin.css'

import './assets/styles.css'

const composeEnhancers =
  isDev() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)))

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <App />
  </Provider>,
)
