import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { createTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

// Adapted from: https://mui.com/material-ui/guides/routing/#global-theme-link
export const LinkBehavior = React.forwardRef(({ href, ...rest }, ref) => (
  <RouterLink ref={ref} to={href} {...rest} />
))

// WARN: This instantiates a new theme each time, use it only once in `App.js`,
//       other components should rely on `useTheme` from '@mui/material/styles'
export const useNewTheme = () => {
  const storedPaletteMode = localStorage.getItem('palette')
  const userPreferredMode = ['dark', 'light'].includes(storedPaletteMode)
    ? storedPaletteMode
    : undefined

  // NOTE: Dark mode is temporarily forced by default, ignoring the preference
  //       provided by the browser. If the light theme improves in the future,
  //       it might be worth reverting this change to restore normal behavior.
  const browserPrefersDarkMode =
    useMediaQuery('(prefers-color-scheme: dark)') || true

  const [paletteMode, setPaletteMode] = React.useState(
    userPreferredMode ?? (browserPrefersDarkMode ? 'dark' : 'light'),
  )

  React.useEffect(() => {
    if (!userPreferredMode) {
      setPaletteMode(browserPrefersDarkMode ? 'dark' : 'light')
    }
  }, [userPreferredMode, browserPrefersDarkMode])

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 630,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },

    components: {
      CustomNumberInput: {
        defaultProps: {
          labelDown: true,
        },
      },

      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },

      MuiCardActions: {
        styleOverrides: {
          root: ({ theme }) => ({
            // NOTE: Fixing https://github.com/mui/material-ui/issues/29819
            '& > :not(:first-of-type)': { marginLeft: 'unset' },
            gap: theme.spacing(1),

            justifyContent: 'end',
          }),
        },
      },

      MuiCardHeader: {
        styleOverrides: {
          root: ({ theme }) => ({
            background: theme.palette.action.focus,
            padding: theme.spacing(1),
          }),
          action: {
            alignSelf: 'center',
            margin: 0,
          },
        },
      },

      MuiInputLabel: {
        styleOverrides: {
          shrink: {
            fontSize: '1.1rem', // Requested by @somorjai
          },
        },
      },

      MuiLink: {
        defaultProps: {
          component: LinkBehavior,
        },
      },

      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: 36,
          },
        },
      },

      MuiListItemText: {
        styleOverrides: {
          root: {
            overflowWrap: 'break-word',
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            '& > legend': {
              fontSize: '0.825em', // Accommodate increased input label font size
            },
          },
        },
      },

      MuiStack: {
        defaultProps: {
          useFlexGap: true,
        },
      },

      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:last-child th, &:last-child td': {
              borderBottom: 'none',
            },
          },
        },
      },
    },

    palette: {
      mode: paletteMode,

      primary: {
        main: '#2196f3',
      },

      sky: {
        day: '#58adf1',
        night: '#222833',
      },
    },

    typography: {
      fontFamily: ['Fira Sans'],
    },
  })

  return {
    theme,
    setPaletteMode,
  }
}

export const SetPaletteModeContext = React.createContext(() => {})
