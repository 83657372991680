import { AUTH, INIT, CONFIG, LANG, ACCOUNT } from '~/constants'
import API from '~/apis/sbApi'
import { INIT as URLS } from '~/constants/urls'

const authenticate = () => async (dispatch) => {
  try {
    await new API().get(URLS.csrfCookie)
    // TODO: Does this even do anything? (The response already sets the cookie.)
    dispatch({
      type: AUTH.SET_CSRF_COOKIE,
    })

    const response = await new API().get(URLS.getUser)

    dispatch({
      type: AUTH.AUTHENTICATED,
      user: response.data.user,
    })
  } catch (error) {
    dispatch({
      type: AUTH.UNAUTHENTICATED,
    })
  }
}

export const initialize = () => async (dispatch) => {
  await dispatch(authenticate())

  try {
    const response = await new API().get(URLS.initialize)

    dispatch({
      type: LANG.SET,
      dictionary: response.data.lang,
    })

    dispatch({
      type: LANG.LOCALE_SET,
      locale: response.data.locale,
    })

    dispatch({
      type: CONFIG.SET_CONFIG,
      config: response.data?.config,
    })

    dispatch({
      type: ACCOUNT.SET_INTENT_COUNT,
      count: response.data?.intent_count,
    })

    dispatch({
      type: INIT.INITIALIZED,
    })
  } catch (error) {
    dispatch({
      type: INIT.UNINITIALIZED,
    })
  }
}
