import isNumeric from 'validator/es/lib/isNumeric'

export const priceFormat = (price, currency = 'EUR') => {
  if (
    !(
      typeof price === 'number' ||
      (typeof price === 'string' && isNumeric(price))
    )
  ) {
    return ''
  }

  return new Intl.NumberFormat('en-EN', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
  }).format(price)
}
