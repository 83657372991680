import { QUOTES } from '~/constants'

const initialState = {
  saveQuoteIsPending: false,
}

const quotes = (state = initialState, action) => {
  switch (action.type) {
    case QUOTES.SAVE_QUOTE_IS_PENDING:
      return { ...state, saveQuoteIsPending: action.value }
    default:
      return state
  }
}

export default quotes
