import { isDev } from './env'
import { combine, bold, greenBg, blackBg, redBg, white } from './colorConsole'

export const responseAdapter = (response) => {
  const success = !!response.success

  response = success ? response.success : response.error

  let adapted = {
    success,
    error: !success,
    method: response?.config?.method,
    url: response?.config?.url,
    status: response?.status,
    statusText: response?.statusText,
    data: response?.data,
    message: response?.data?.message,
  }

  if (response?.data?.errors) {
    adapted = {
      ...adapted,
      errors: Object.fromEntries(
        Object.entries(response?.data?.errors).map(([field, messageArray]) => [
          field,
          messageArray[0],
        ]),
      ),
    }
  }

  logResponse(adapted)

  return adapted
}

const logResponse = (response) => {
  if (!isDev()) return false

  const { success, method, url, status } = response

  const styles = [
    [success ? greenBg : redBg, white, bold],
    [blackBg, white, bold],
    [success ? greenBg : redBg, white, bold],
  ]

  console.log(
    `%c ${method?.toUpperCase()} %c${url}%c${status}`,
    ...styles.map((styleArray) => combine(...styleArray)),
  )
}
